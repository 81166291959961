import { isEmail, isInList, isPhoneNumber, isRequired, isSSN, isTrue } from '@/rules'
import InterviewAPI, { InterviewFlagType, InterviewFlagCause, Snapshot, QuestionCategory as QC, QuestionKey as QK } from '@/api/Interview'
import { ssKeyInterviewID } from '@/config/app.config'
import { RouteName } from '@/router/routes/constants.js'
import router from '@/router/'
import { getFlowStepInfo } from '@/api/StepInfo'
import { bindAuthReason } from '@/api/Interview/constants'

const interviewModule = {
  namespaced: true,
  name: 'InterviewModule',
  state: {
    accountVerificationStatus: undefined,
    allHealthFollowups: undefined,
    bindOverride: false,
    answers: {},
    editMode: false,
    expiresAt: null,
    finalQuoteShown: undefined,
    hasDrivingFollowup: undefined,
    hasIdentityFollowup: undefined,
    interview: null,
    lastStatus: undefined,
    maxSteps: undefined,
    questionsMap: {},
    reflexiveHealthSteps: [],
    step: undefined,
    validatedAddress: false,
    enteredProductVariant: undefined,
    tempInterviewData: {}
  },
  getters: {
    accountID: (state) => state.interview.accountId,
    validatedAddress: (state) => state.validatedAddress,
    interview: (state) => state.interview,
    lastStatus: (state) => state.lastStatus,
    questions: (state) => state.interview.questions,
    questionsMap: (state) => state.questionsMap,
    answers: (state) => Object.assign({}, state.answers),
    activeSectionsMap: (state) => state.activeSections,
    enteredProductVariant: (state) => state.enteredProductVariant,
    tempInterviewData: (state) => state.tempInterviewData,
    answersForKeys: (state) => (keys) => {
      let result = {}
      keys.forEach((k) => {
        result[k] = state.answers[k]
      })
      return result
    },
    editMode: (state) => state.editMode,
    interviewID: (state) => {
      const sessionID = window.sessionStorage.getItem(ssKeyInterviewID)
      if (state.interview) {
        if (!sessionID) {
          // rehydrate the session variable because it got erased for some reason
          window.sessionStorage.setItem(ssKeyInterviewID, state.interview.id)
        }
        return state.interview.id
      } else if (sessionID) {
        return sessionID
      }
      return undefined
    },

    hasSavedInterview: (state, getters) => !!getters.interview,

    reflexiveHealthSteps: (state) => {
      return state.reflexiveHealthSteps
    },

    getQuestionByKey: (state) => (key) => {
      if (!state.interview) return null
      const q = state.interview.questions.find((question) => question.key === key)
      if (q === undefined) {
        return {
          key: key
        }
      }
      return q
    },

    getQuestionOptionsByKey: (state, getters) => (key) => {
      if (!state.interview) return null
      const question = getters.getQuestionByKey(key)
      if (question) {
        return question.options
      }
      return null
    },

    getReflexiveByKey: (state, getters) => (key) => {
      if (!state.interview) return null
      return state.interview.reflexives.find((rex) => rex.targetKey === key)
    },

    getReflexiveQuestionsForSourceKey: (state) => (key, isSatisfied) => {
      if (typeof isSatisfied !== 'boolean') {
        isSatisfied = null
      }
      const questions = []
      const refs = state.interview.reflexives.filter((ref) => {
        const isMatch = ref.sourceKey === key
        if (isSatisfied !== null) {
          return ref.isSatisfied !== isSatisfied ? false : isMatch
        }
        return isMatch
      })
      for (const ref of refs) {
        questions.push(state.interview.questions.find((question) => question.key === ref.targetKey))
      }
      return questions
    },

    hasExpired: (state) => false,

    answerForKey: (state) => (answer, key) => {
      if (answer === null || typeof answer === 'undefined') {
        answer = []
      }
      if (key === QK.FAMILY_HISTORY) {
        return {
          questionKey: key,
          familyHistory: answer
        }
      }
      return {
        questionKey: key,
        values: Array.isArray(answer) ? answer : [answer]
      }
    },

    mapToAnswers: (state, getters) => (answerMap, keys, answeredOnly) => {
      if (typeof answeredOnly !== 'boolean') {
        answeredOnly = false
      }
      const answers = []
      keys.forEach((key) => {
        const answer = getters.answerForKey(answerMap[key], key)

        if (!answeredOnly || (answer && answer.values.length)) {
          answers.push(answer)
        }
      })
      return answers
    },

    validateKeys: (state, getters) => (keys, answers) => {
      for (var key of keys) {
        const question = state.questionsMap[key]
        const rules = getters.rulesForQuestion(question)
        for (let rule of rules) {
          if (rule(answers[key]) !== true) {
            return false
          }
        }
      }
      return true
    },

    // validation
    rulesForQuestion: (state, getters) => (q) => {
      let rules = []
      if (!q) {
        return rules
      }
      // if (q.valueType == ValueType.CURRENCY) {
      //   rules.push(isCurrency())
      // }
      let keyRules = getters.rulesForKey(q.key)
      return rules.concat(keyRules)
    },

    rulesForKey: (state, getters) => (key, params) => {
      if (!state.interview) return []
      const question = getters.getQuestionByKey(key)
      if (!question) {
        return []
      }
      let matchedRules = []
      const hasRequireOverride = params && params.requiredOverride
      if (question.required && !hasRequireOverride) {
        matchedRules.push(isRequired())
      }
      if (question.options && question.options.length > 0) {
        matchedRules.push(isInList(question.options))
      }
      const reflexive = getters.getReflexiveByKey(key)
      if (reflexive) {
        if (reflexive.conditions && reflexive.conditions.length > 0) {
          // do something here eventually???
        }
      }
      switch (key) {
        case QK.EMAIL_ADDRESS:
        case QK.BENEFICIARY_EMAIL_ADDRESS:
          matchedRules.push(isEmail())
          break
        case QK.MOBILE_NUMBER:
        case QK.BENEFICIARY_MOBILE_PHONE_NUMBER:
          matchedRules.push(isPhoneNumber())
          break
        case QK.SSN:
          matchedRules.push(isSSN())
          break
        case QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT:
          matchedRules.push(isTrue())
      }
      return matchedRules
    },

    maskforKey: (state, getters) => (key) => {
      switch (key) {
        case QK.WEIGHT_POUNDS:
          return '###'
        case QK.MOBILE_NUMBER:
        case QK.BENEFICIARY_MOBILE_PHONE_NUMBER:
          return '###-###-####'
        case QK.SSN:
          return '###-##-####'
        case QK.DRIVERS_LICENSE_NUMBER:
          return 'NNNNNNNNNNNNNNNNNNN'
        default:
          return null
      }
    },

    parseBool: (state, getters) => (s, defaultValue = false) => {
      const typeS = typeof s
      if (typeS === 'undefined' || typeS === null) return defaultValue
      if (typeS === 'boolean') return s
      if (typeS === 'number') return s !== 0
      if (typeS !== 'string') return defaultValue
      const ls = s.toLowerCase()
      if (ls === 'true') return true
      if (ls === '1') return true
      if (ls === 'false') return false
      if (ls === '0') return false
      return defaultValue
    },

    allHealthFollowups: (state) => {
      if (state.allHealthFollowups) {
        return state.allHealthFollowups
      }
      const sessionHealthFollowups = window.sessionStorage.getItem('allHealthFollowups')
      if (sessionHealthFollowups !== null) {
        return sessionHealthFollowups
      }
      return []
    },
    hasDrivingFollowup: (state) => {
      if (state.hasDrivingFollowup !== undefined) {
        return state.hasDrivingFollowup
      }
      const sessionDrivingFollowup = window.sessionStorage.getItem('hasDrivingFollowup')
      if (sessionDrivingFollowup !== null) {
        return sessionDrivingFollowup
      }
      return false
    },
    hasDiagnosisAnswers: (state, getters) => {
      const allHealthFollowups = getters.allHealthFollowups
      return allHealthFollowups && allHealthFollowups.length > 0 &&
        allHealthFollowups[0].hasOwnProperty('answerDetails') &&
        allHealthFollowups[0].answerDetails !== null
    },
    hasHealthConditions: (state, getters) => {
      const allHealthFollowups = getters.allHealthFollowups
      return allHealthFollowups && allHealthFollowups.length > 0 &&
        allHealthFollowups[0].hasOwnProperty('value') &&
        allHealthFollowups[0].value !== null
    },
    getMaxSteps: (state) => {
      return state.maxSteps
    },
    finalQuoteShown: (state) => {
      if (state.finalQuoteShown !== undefined) {
        return state.finalQuoteShown
      }
      const sessionInterstitialShown = window.sessionStorage.getItem('finalQuoteShown')
      if (sessionInterstitialShown !== null) {
        return sessionInterstitialShown
      }
      return false
    },
    hasIdentityFollowup: (state) => {
      if (state.hasIdentityFollowup !== undefined) {
        return state.hasIdentityFollowup
      }
      return false
    },
    totalFinalizeFlowSteps: (state, getters) => {
      const allHealthFollowups = getters.allHealthFollowups
      const hasDrivingFollowup = getters.hasDrivingFollowup
      const hasIdentityFollowup = getters.hasIdentityFollowup
      let totalSteps = 0
      if (allHealthFollowups && allHealthFollowups.length > 0) {
        totalSteps += allHealthFollowups.length
      }
      if (hasIdentityFollowup || hasDrivingFollowup) totalSteps += 1
      return totalSteps
    }
  },
  mutations: {
    ACCOUNT_VERIFICATION_STATUS (state, val) {
      state.accountVerificationStatus = val.redirectStatus
    },
    UPDATE_ENTERED_PRODUCT_VARIANT (state, val) {
      state.enteredProductVariant = val
    },
    UPDATE_TEMP_INTERVIEW_DATA (state, val) {
      state.tempInterviewData = val
    },
    SET_USER_STATE (state, val) {
      state.userState = val
    },
    BIND_OVERRIDE (state, val) {
      state.bindOverride = val
    },
    UPDATE_INTERVIEW (state, interview) {
      state.interview = interview
      window.sessionStorage.setItem(ssKeyInterviewID, interview.id)
    },

    UPDATE_REFLEXIVES (state, reflexives) {
      state.interview.reflexives = reflexives
    },

    SET_QUESTIONS_MAP (state, questionsMap) {
      state.questionsMap = Object.assign({}, questionsMap)
    },

    UPDATE_QUESTIONS_MAP (state, questionsMap) {
      const updated = Object.assign({}, state.questionsMap)
      for (const [k, v] of Object.entries(questionsMap)) {
        updated[k] = v
      }
      state.questionsMap = updated
    },

    SET_ANSWERS (state, answers) {
      state.answers = Object.assign({}, answers)
    },

    UPDATE_ANSWERS (state, answers) {
      const updated = Object.assign({}, state.answers)
      for (const [k, v] of Object.entries(answers)) {
        updated[k] = v
      }
      state.answers = updated
    },

    UPDATE_ACCOUNT_ID (state, accountId) {
      state.interview.accountId = accountId
    },

    UPDATE_STATUS (state, status) {
      if (state.interview) state.interview.status = status
      state.lastStatus = status
    },

    UPDATE_QUOTE (state, quote) {
      // adding this check because state.interview can be null when editing on admin:
      if (state && state.interview !== null) {
        state.interview.quote = quote
      }
    },

    UPDATE_REFLEXIVE_HEALTH_STEPS (state, sourceKeys) {
      state.reflexiveHealthSteps = sourceKeys
    },

    CLEAR_INTERVIEW (state) {
      window.sessionStorage.removeItem(ssKeyInterviewID)
      state.interview = null
    },

    UPDATE_VALIDATED_ADDRESS (state, isValidAddress) {
      state.validatedAddress = isValidAddress
    },

    SET_ALL_HEALTH_FOLLOWUPS (state, healthFollowups) {
      if (healthFollowups.length > 0 && healthFollowups[0].value) {
        state.allHealthFollowups = healthFollowups
        window.sessionStorage.setItem('allHealthFollowups', state.allHealthFollowups)
      } else {
        // reset
        state.allHealthFollowups = undefined
        window.sessionStorage.removeItem('allHealthFollowups')
      }
    },

    SET_HEALTH_DETAILS (state, payload) {
      const { index, healthDetails } = payload
      const followup = state.allHealthFollowups[index]
      state.allHealthFollowups[index] = {
        ...followup,
        answerDetails: healthDetails
      }
      window.sessionStorage.setItem('allHealthFollowups', state.allHealthFollowups)
    },

    UPDATE_HAS_DRIVING_FOLLOWUP (state, hasDrivingFollowup) {
      state.hasDrivingFollowup = hasDrivingFollowup
      window.sessionStorage.setItem('hasDrivingFollowup', hasDrivingFollowup)
    },

    UPDATE_EDIT_MODE (state, editMode) {
      state.editMode = editMode
    },
    UPDATE_STEP_OBJECT (state, payload) {
      state.step = payload
    },
    UPDATE_MAX_STEPS (state, payload) {
      state.maxSteps = payload
    },
    UPDATE_FINAL_QUOTE_SHOWN (state, finalQuoteShown) {
      state.finalQuoteShown = finalQuoteShown
      window.sessionStorage.setItem('finalQuoteShown', finalQuoteShown)
    },
    UPDATE_HAS_IDENTITY_FOLLOWUP (state, hasIdentityFollowup) {
      state.hasIdentityFollowup = hasIdentityFollowup
    }
  },

  actions: {
    updateEnteredProductVariant ({ commit }, variant) {
      commit('UPDATE_ENTERED_PRODUCT_VARIANT', variant)
    },
    updateTempInterviewData ({ commit }, data) {
      commit('UPDATE_TEMP_INTERVIEW_DATA', data)
    },
    clearTempInterviewData ({ commit }) {
      commit('UPDATE_TEMP_INTERVIEW_DATA', {})
    },
    setUserState ({ commit }, payload) {
      commit('SET_USER_STATE', payload.userState)
    },
    async getQuickQuote ({ rootState }, payload) {
      const response = await rootState.apolloClient.query({
        ...InterviewAPI.getQuickQuote(payload.age, payload.gender, payload.income, payload.childAge)
      })
      if (response) {
        return response.data
      }
      return false
    },
    async setQuoteLocked ({ commit, dispatch, rootState }, payload) {
      const rsp = await rootState.apolloClient.mutate({
        ...InterviewAPI.SetQuoteLocked(payload.interviewID, payload.locked)
      })
      return rsp
    },
    async recordInteraction ({ commit, dispatch, rootState }, payload) {
      const rsp = await rootState.apolloClient.mutate({
        ...InterviewAPI.RecordInteraction(payload.interviewID, payload.actionType)
      })
      return rsp
    },
    async checkFollowupSectionsActivated ({ commit, dispatch }, payload) {
      const { sections } = await dispatch('fetchInterviewQuoteAndSections', {
        id: payload.id,
        categories: [QC.DRIVING_FOLLOWUP, QC.IDENTITY_FOLLOWUP]
      })
      if (sections && sections.length > 0) {
        sections.forEach(s => {
          if (s.category === QC.DRIVING_FOLLOWUP) {
            dispatch('updateHasDrivingFollowup', { hasDrivingFollowup: s.active })
          }
          if (s.category === QC.IDENTITY_FOLLOWUP) {
            dispatch('updateHasIdentityFollowup', { hasIdentityFollowup: s.active })
          }
        })
      }
      return {
        navigate: true,
        proceed: true
      }
    },

    updateHasIdentityFollowup ({ commit }, payload) {
      commit('UPDATE_HAS_IDENTITY_FOLLOWUP', payload.hasIdentityFollowup)
    },
    updateMaxSteps ({ commit }, payload) {
      commit('UPDATE_MAX_STEPS', payload)
    },
    updateFinalQuoteShown ({ commit }, finalQuoteShown) {
      commit('UPDATE_FINAL_QUOTE_SHOWN', finalQuoteShown)
    },

    updateEditMode ({ commit }, editMode) {
      commit('UPDATE_EDIT_MODE', editMode)
    },

    clearInterview ({ commit }) {
      commit('CLEAR_INTERVIEW')
    },

    onSignOut ({ commit }) {
      commit('CLEAR_INTERVIEW')
    },

    setReflexiveHealthSteps ({ commit, rootState }, sourceKeys) {
      commit('UPDATE_REFLEXIVE_HEALTH_STEPS', sourceKeys)
    },
    async bindAgentToInterview ({ rootState }, payload) {
      let result = await rootState.apolloClient.mutate({
        ...InterviewAPI.bindAgentToInterview(payload.id, payload.agentId),
        fetchPolicy: 'no-cache'
      })
      return result
    },
    extractQuestionAnswerMaps ({ commit }, questions) {
      if (!questions) {
        return { questions: {}, answers: {} }
      }

      const qMap = {}
      const aMap = {}

      questions.forEach((q) => {
        const map = {}
        for (const k in q) {
          if (k !== 'answers') {
            map[k] = q[k]
          }
        }
        qMap[q.key] = map
        if (q.key === QK.FAMILY_HISTORY && q.answered === true) {
          aMap[q.key] = q.answer.familyHistory
        } else if (q.answered === false && q.defaultAnswers) {
          aMap[q.key] = q.multipleChoice ? q.defaultAnswers : q.defaultAnswers[0]
        } else if (q.answers) {
          aMap[q.key] = q.multipleChoice ? q.answers : q.answers[0]
        } else {
          aMap[q.key] = q.multipleChoice ? [] : undefined
        }
      })

      commit('UPDATE_QUESTIONS_MAP', qMap)
      commit('UPDATE_ANSWERS', aMap)

      return { questions: qMap, answers: aMap }
    },
    async getVerificationStatus ({ rootState }, payload) {
      const { id } = payload
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.getVerificationStatus(id),
        fetchPolicy: 'no-cache'
      })
      const { data } = result
      if (data) {
        return data
      }
      return false
    },
    async createInterview ({ commit, dispatch, rootState }, payload) {
      const { stateValue, bind } = payload
      // eslint-disable-next-line no-console
      console.log(`Creating interview for state '${stateValue}' ...`, 'bind?', bind)
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.create(stateValue, bind)
      })
      const data = result.data
      if (data && data.createInterview) {
        const interview = data.createInterview
        if (bind) commit('UPDATE_INTERVIEW', interview)
        await dispatch('extractQuestionAnswerMaps', interview.questions)
        return interview
      }
      return false
    },

    async submitInterview ({ commit, rootState }, payload) {
      let result
      if (payload.async) {
        result = await rootState.apolloClient.mutate({
          ...InterviewAPI.submitAsync(payload.id),
          fetchPolicy: 'no-cache'
        })
      } else {
        result = await rootState.apolloClient.mutate({
          ...InterviewAPI.submit(payload.id),
          fetchPolicy: 'no-cache'
        })
      }

      const data = result.data
      if (data && data.submitInterview) {
        return data.submitInterview.id
      }
      return false
    },

    async loadinInterviewToState ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.find(payload.id),
        fetchPolicy: 'no-cache'
      })
      commit('UPDATE_INTERVIEW', result.data.interview)
    },
    async redirectResult ({ rootState, commit }, payload) {
      const response = await rootState.apolloClient.query({
        ...InterviewAPI.getEmailRedirect(payload.email)
      })
      if (response) {
        commit('ACCOUNT_VERIFICATION_STATUS' + '', response.data?.getEmailRedirect)
        if (response.data?.getEmailRedirect && response.data?.getEmailRedirect !== 0) {
          commit('BIND_OVERRIDE' + '', true)
        }
        return response.data?.getEmailRedirect
      }
      return false
    },
    async reloadInterview ({ commit, rootState, dispatch }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.find(payload.id),
        fetchPolicy: 'no-cache'
      })
      const data = result.data
      if (result.errors) {
        return result.errors
      } else if (data && data.interview) {
        const interview = data.interview
        await dispatch('interviewQuestionAnswerMaps', {
          id: interview.id,
          active: true
        })
        commit('UPDATE_INTERVIEW', interview)
        return interview
      }
      return false
    },

    async fetchInterviewQuote ({ rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.FetchInterviewQuote(payload.id, payload.where),
        fetchPolicy: 'no-cache'
      })
      const data = result.data
      if (result.errors) {
        return result.errors
      } else if (data && data.interview) {
        const { quote } = data.interview
        return { quote }
      }
      return false
    },

    async fetchInterviewQuoteAndSections ({ rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.FetchInterviewQuoteAndSections(payload.id, payload.where),
        fetchPolicy: 'no-cache'
      })
      const data = result.data
      if (result.errors) {
        return result.errors
      } else if (data && data.interview) {
        const { sections, quote } = data.interview
        return { sections, quote }
      }
      return false
    },

    async fetchInterviewSectionCount ({ rootState, commit }, payload) {
      let sections
      const group = (payload.route === 'signin' || payload.route === 'signin-callback') && this.state.interview.step === 'CHECKOUT' ? 'CHECKOUT' : payload.group
      if (payload.group === 'FINALIZE') {
        sections = await rootState.apolloClient.query({
          ...InterviewAPI.getInterviewStepCount(payload.id, payload.group),
          fetchPolicy: 'no-cache'
        })
      }

      const titleInfo = await getFlowStepInfo(payload.route, group, sections)
      commit('UPDATE_STEP_OBJECT', payload.group)
      return titleInfo
      // /(result)
    },

    async fetchInterviewCurrentSection ({ rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.fetchInterviewCurrentSection(payload.id),
        fetchPolicy: 'no-cache'
      })
      return result?.data?.interview?.currentSection || false
    },

    async fetchInterviewSection ({ rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.fetchInterviewSection(payload.id, payload.sectionId),
        fetchPolicy: 'no-cache'
      })
      return result?.data?.interview?.section || false
    },

    async adminReplaceAccountInterview ({ commit, rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...InterviewAPI.adminReplaceAccountInterview(payload.id, payload.accountId)
      })
      if (res && res?.data) {
        return res?.data?.adminReplaceAccountInterview
      }
      return false
    },

    async bindInterview ({ commit, dispatch, rootState }, payload) {
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.bind(payload.id, bindAuthReason(payload.authReason), payload.accountId)
      })
      if (result) {
        if (payload.accountId) {
          commit('UPDATE_ACCOUNT_ID', payload.accountId)
        } else {
          const account = await dispatch('account/getAccount', null, { root: true })
          if (account) {
            commit('UPDATE_ACCOUNT_ID', account.id)
          }
        }

        return result.data.bindInterview
      }
      return false
    },

    async updateInterview ({ commit }, interview) {
      commit('UPDATE_INTERVIEW', interview)
    },

    updateAnswers ({ commit }, answers) {
      commit('UPDATE_ANSWERS', answers)
    },

    async interviewQuestionAnswerMaps ({ dispatch }, payload) {
      return dispatch('interviewQuestions', payload).then((questions) => {
        return dispatch('extractQuestionAnswerMaps', questions)
      })
    },

    async interviewQuestions ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.questions(payload.id, toQuestionWhere(payload)),
        fetchPolicy: 'no-cache'
      })
      const questions = []
      if (result.data) {
        result.data.questions.forEach((item) => {
          // pre-process here if needed
          questions.push(item)
        })
      }
      return questions
    },

    async interviewReflexives ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.reflexives(payload.id, toReflexiveWhere(payload)),
        fetchPolicy: 'no-cache'
      })
      const reflexives = []
      if (result.data) {
        result.data.reflexives.forEach((item) => {
          reflexives.push({
            targetKey: item.targetKey,
            sourceKey: item.sourceKey,
            isSatisfied: item.isSatisfied,
            category: item.category,
            target: { ...item.target }
          })
        })
      }
      return reflexives
    },

    async reloadReflexives ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.reflexives(payload.id, {}),
        fetchPolicy: 'no-cache'
      })
      if (result.data) {
        const reflexives = result.data.reflexives
        commit('UPDATE_REFLEXIVES', reflexives)
        return reflexives
      }
      return false
    },

    async validateInterviewAnswers ({ commit, dispatch, rootState, getters, rootGetters }, payload) {
      const id = payload.id
      const answers = payload.answers
      const searchKey = payload.searchKey

      if (!id || !answers) {
        return { success: false, errors: [] }
      }
      const response = await rootState.apolloClient.query({
        ...InterviewAPI.validateAnswers(id, answers),
        context: payload.context || {}
      })
      if (searchKey && payload.context && payload.context.requestId) {
        // discard the response if the search key is defined and the request id is not the latest request id
        const reqId = rootGetters['search/lastRequestIdForKey'](searchKey)
        if (payload.context.requestId !== reqId) {
          // eslint-disable-next-line no-console
          console.log(`search: skipping request '${payload.context.requestId}'`)
          return {
            success: false,
            errors: ['Skipped result']
          }
        }
      }

      const data = response.data
      return {
        success: true,
        results: data.validateInterviewAnswers,
        requestId: payload.context.requestId
      }
    },

    async updateInterviewAnswers ({ commit, dispatch, rootState, getters, rootGetters }, payload) {
      const id = payload.id
      const answers = payload.answers
      const activeOnly = typeof payload.activeOnly === 'boolean' ? payload.activeOnly : true
      const searchKey = payload.searchKey
      let response
      try {
        response = await rootState.apolloClient.mutate({
          ...InterviewAPI.updateAnswers(id, answers),
          context: payload.context || {}
        })
      } catch (e) {
        const status = await dispatch('getStatus', {
          id: id,
          active: true
        })
        let redirectTo
        const additionalFlags = [InterviewFlagCause.EXISTING_FINGERPRINT]
        const estimateFlags = status.flags.filter((f) => additionalFlags.includes(f.cause))
        estimateFlags.forEach((item) => {
          if (item.type === InterviewFlagType.SIGN_IN_REQUIRED && rootGetters['auth/isAuthenticated'] === false) {
            window.sessionStorage.setItem('lastCategory', InterviewFlagType.SIGN_IN_REQUIRED)
            redirectTo = RouteName.CHECKOUT_SIGNIN
          }
        })
        if (redirectTo) {
          await router.push({ name: redirectTo })
          return { success: false, errors: [] }
        }
        throw e
      }

      if (searchKey && payload.context && payload.context.requestId) {
        // discard the response if the search key is defined and the request id is not the latest request id
        const reqId = rootGetters['search/lastRequestIdForKey'](searchKey)
        if (payload.context.requestId !== reqId) {
          // eslint-disable-next-line no-console
          console.log(`search: skipping request '${payload.context.requestId}'`)
          return {
            success: false,
            errors: ['Skipped result']
          }
        }
      }

      const data = response.data
      const results = data.putInterviewAnswers
      const reflexives = {}
      const values = {}
      const errors = {}

      if (!activeOnly) {
        /*
        Process all questions
        */
        results.forEach((res) => {
          const key = res.questionKey
          if (res.success) {
            values[key] = res.values
            if (res.reflexives.length) {
              reflexives[key] = res.reflexives
            }
          } else {
            errors[key] = res.message
          }
        })
      } else {
        /*
        Process only active questions
        */
        let activeReflexiveKeys = []
        let inactiveReflexiveKeys = []

        results.forEach((res) => {
          const key = res.questionKey

          if (res.reflexives.length) {
            reflexives[key] = res.reflexives

            let activeRefs = []
            let inactiveRefs = []
            res.reflexives.forEach((r) => {
              if (r.isSatisfied) {
                activeRefs.push(r)
              } else {
                inactiveRefs.push(r)
              }
              if (activeRefs.length) {
                activeReflexiveKeys = activeReflexiveKeys.concat(activeRefs.map((r) => r.targetKey))
              }
              if (inactiveRefs.length) {
                inactiveReflexiveKeys = inactiveReflexiveKeys.concat(inactiveRefs.map((r) => r.targetKey))
              }
            })
          }
        })

        // iterate for errors for any active questions
        results.forEach((res) => {
          const key = res.questionKey

          // store successfully updated question values
          if (res.success) {
            values[key] = res.values
          } else {
            // if this is an active reflexive OR if its NOT an inactive reflexive (i.e. active question)
            if (activeReflexiveKeys.indexOf(key) > -1 || inactiveReflexiveKeys.indexOf(key) === -1) {
              errors[key] = res.message
            }
          }
        })
      }
      if (Object.keys(values).length > 0) {
        dispatch('extractQuestionAnswerMaps', results)
      }

      if (Object.keys(errors).length) {
        return {
          data,
          requestId: response.requestId,
          success: false,
          reflexives,
          errors,
          results
        }
      }

      return {
        data,
        requestId: response.requestId,
        success: true,
        reflexives,
        errors: null,
        results
      }
    },

    async signatureEmbedUrl ({ commit, rootState }, interviewID) {
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.signatureEmbed(interviewID),
        fetchPolicy: 'no-cache'
      })
      const data = result.data
      if (data) {
        return data.interviewSignatureEmbed.url
      }
      return null
    },

    async findOccupations ({ commit, rootState }, term) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.findOccupations(term)
      })
      const data = result.data
      const matches = data.occupations
      return matches.map((match) => {
        return {
          text: match.name,
          value: match.key
        }
      })
    },

    async findOccupationWithKey ({ commit, rootState }, key) {
      if (key) {
        const result = await rootState.apolloClient.query({
          ...InterviewAPI.occupationsWithKeys([key])
        })
        const data = result.data
        if (data && data.occupations.length === 1) {
          return {
            text: data.occupations[0].name,
            value: data.occupations[0].key
          }
        }
        return null
      }
      return undefined
    },

    async findMedicationsSettingValueFromKey ({ commit, rootState }, term) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.findMedications(term)
      })
      const data = result.data
      if (data) {
        const matches = data.medications
        return matches.map((match) => {
          return {
            text: match.activeName,
            value: match.key
          }
        })
      }
      return []
    },

    async findMedicationsSettingValueFromActiveName ({ commit, rootState }, term) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.findMedications(term)
      })
      const data = result.data
      if (data) {
        const matches = data.medications
        return matches.map((match) => {
          return {
            text: match.activeName,
            value: match.activeName
          }
        })
      }
      return []
    },

    async findMedicationsWithKeys ({ commit, rootState }, keys) {
      if (!Array.isArray(keys)) {
        keys = [keys]
      }
      if (keys.length === 0 || !keys[0] || keys[0].length === 0) {
        return []
      }

      const result = await rootState.apolloClient.query({
        ...InterviewAPI.medicationsWithKeys(keys)
      })
      const data = result.data
      if (data) {
        const meds = data.medications
        return meds.map((m) => {
          return {
            text: m.activeName,
            value: m.key
          }
        })
      }
      return []
    },

    async findMedicationsWithNames ({ commit, rootState }, names) {
      if (!Array.isArray(names)) {
        names = [names]
      }
      if (names.length === 0 || !names[0] || names[0].length === 0) {
        return []
      }

      const result = await rootState.apolloClient.query({
        ...InterviewAPI.medicationsWithNames(names)
      })
      const data = result.data
      if (data) {
        const meds = data.medications
        return meds.map((m) => {
          return {
            text: m.activeName,
            value: m.activeName
          }
        })
      }
      return []
    },

    async findDiagnoses ({ rootState }, term) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.findDiagnoses(term)
      })
      const data = result.data
      if (data && data.diagnoses) {
        const matches = data.diagnoses
        return matches.map(match => {
          return {
            text: match.description,
            value: match.code
          }
        })
      }
      return []
    },

    async findDiagnosesWithCodes ({ rootState }, codes) {
      if (!Array.isArray(codes)) {
        codes = [codes]
      }
      if (codes.length === 0 || !codes[0] || codes[0].length === 0) {
        return []
      }

      const result = await rootState.apolloClient.query({
        ...InterviewAPI.diagnosesWithCodes(codes)
      })
      const data = result.data
      if (data && data.diagnoses.length === 1) {
        return {
          text: data.diagnoses[0].description,
          value: data.diagnoses[0].code
        }
      }
      return []
    },

    async getQuote ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.getQuote(payload.id, payload.parameter),
        fetchPolicy: 'no-cache'
      })
      let quote = null
      if (result.data && result.data.quote && result.data.quote.success === true) {
        if (payload?.spread === true) {
          quote = result.data.quote
          commit('UPDATE_QUOTE', quote.quote)
        } else {
          quote = result.data.quote.quote
          commit('UPDATE_QUOTE', quote)
        }
      }
      return quote
    },

    async updateQuote ({ commit, rootState }, payload) {
      const override = payload.override || undefined
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.updateQuote(payload.id, payload.parameter, override)
      })
      const quote = result.data.updateQuote
      this.quoteLocked = quote.locked || false
      commit('UPDATE_QUOTE', quote)
      return quote
    },

    async resetQuote ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.resetQuote(payload.id)
      })

      const quote = result.data.resetQuote
      commit('UPDATE_QUOTE', quote)
      return quote
    },

    async  getSnapshot ({ commit, rootState }, payload) {
      let snapshot = new Snapshot(commit, rootState, payload?.id)
      await snapshot.update()
      return snapshot
    },

    async getStatus ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.query({
        ...InterviewAPI.getStatus(payload.id),
        fetchPolicy: 'no-cache'
      })
      let status = null
      let returnData = null
      if (result.data) {
        returnData = result.data.interview
        status = result.data.interview.status
        commit('UPDATE_STATUS', status)
      }

      return returnData
    },

    async requireFinalQuote ({ commit, rootState }, payload) {
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.requireFinalQuote(payload.id, payload.requireFinalQuote)
      })
      let interview = null
      if (result.data && result.data.interview) {
        const status = result.data.interview.interview.status
        interview = result.data.interview.interview
        commit('UPDATE_STATUS', status)
      }
      return interview
    },

    async setInterviewMetadata ({ commit, rootState }, payload) {
      let metadataInput
      const { id, metadata } = payload
      if (Array.isArray(metadata)) {
        metadataInput = metadata
      } else if (typeof metadata === 'object') {
        metadataInput = [metadata]
      } else {
        metadataInput = []
      }
      const result = await rootState.apolloClient.mutate({
        ...InterviewAPI.setInterviewMetadata(id, metadataInput)
      })
      if (result?.data?.interview?.error !== null) {
        return false
      }
      return true
    },

    // TODO: replace this when endpoint is rewritten
    async getIsSupportedState ({ commit, rootState }, payload) {
      const { state } = payload
      let data
      if (state) {
        const result = await rootState.apolloClient.query({
          ...InterviewAPI.getIsSupportedState(payload.state)
        })
        data = result.data.isSupportedState
      }
      if (data) {
        return {
          isSupported: data.isSupported,
          message: data.message
        }
      }
    },

    updateValidatedAddress ({ commit }, payload) {
      commit('UPDATE_VALIDATED_ADDRESS', payload.isValidAddress)
    },

    setAllHealthFollowups ({ commit }, payload) {
      commit(
        'SET_ALL_HEALTH_FOLLOWUPS',
        payload.allHealthFollowups.map((value) => {
          return {
            value,
            answerDetails: null
          }
        })
      )
    },

    setHealthDetails ({ commit }, payload) {
      commit('SET_HEALTH_DETAILS', payload)
    },

    updateHasDrivingFollowup ({ commit }, payload) {
      commit('UPDATE_HAS_DRIVING_FOLLOWUP', payload.hasDrivingFollowup)
    }
  }
}

export default interviewModule

const toQuestionWhere = (payload) => {
  payload = Object.assign({}, payload)
  const where = {}
  if (Array.isArray(payload.defaultCategories)) {
    where.defaultCategories = payload.defaultCategories
  }
  if (Array.isArray(payload.keys)) {
    where.keys = payload.keys
  }
  if (typeof payload.remaining === 'boolean') {
    where.remaining = payload.remaining
  }
  if (payload.targetOfReflexives) {
    where.targetOfReflexives = toReflexiveWhere(payload.targetOfReflexives)
  }
  if (Array.isArray(payload.concerns)) {
    where.concerns = payload.concerns
  }
  return where
}

const toReflexiveWhere = (payload) => {
  payload = Object.assign({}, payload)

  const where = {}
  if (typeof payload.category === 'string') {
    where.category = payload.category
  }
  if (typeof payload.sourceKey === 'string') {
    where.sourceKey = payload.sourceKey
  }
  if (typeof payload.remaining === 'boolean') {
    where.remaining = payload.remaining
  }
  return where
}
