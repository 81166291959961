import gql from 'graphql-tag'

export default {
  GetAgent: (agentId) => ({
    query: gql`
      query GetAgent($agentId: String!) {
        agent(id: $agentId) {
          id
        }
      }
    `,
    variables: {
      agentId
    }
  }),
  GetAgentInterviews: (agentId) => ({
    query: gql`
      fragment agentInterviews on InterviewSummary {
        applicant {
          fullName
          emailAddress
          phoneNumbers {
            number
            type
          }
        }
        created
        id
        status
        updated
        agentCommission
        monthlyPremium
        imoStatus
      }
      query GetAgentInterviews($agentId: String!) {
        agent (id: $agentId) {
          agentEmail
          name
        },
        open: agentInterviews(agentId: $agentId, interviewStatus: OPEN) {
          ...agentInterviews,
        },
        closed: agentInterviews(agentId: $agentId, interviewStatus: CLOSED) {
          ...agentInterviews,
          policy {
            policyId
            dateIssued
          }
        }
      }
    `,
    variables: {
      agentId
    }
  }),
  GetAgencyInterviews: (agencyId) => ({
    query: gql`
      fragment agencyInterviews on InterviewSummary {
        applicant {
          fullName
          emailAddress
          phoneNumbers {
            number
            type
          }
        }
        agent {
          agentEmail
          name
        }
        created
        id
        status
        updated
        agencyCommission
        agentCommission
        monthlyPremium
        imoStatus
      }
      query GetAgencyInterviews($agencyId: String!) {
        agency(id: $agencyId) {
            name
            id
        },
        open: agencyInterviews(agencyId: $agencyId, interviewStatus: OPEN) {
          ...agencyInterviews,
        },
        closed: agencyInterviews(agencyId: $agencyId, interviewStatus: CLOSED) {
          ...agencyInterviews,
          policy {
            policyId
            dateIssued
          }
        }
      }
    `,
    variables: {
      agencyId
    }
  })
}
