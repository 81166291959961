<template>
  <div class="customer-detail mq-container">
    <header class="df-header df-header__p-top flow__header">
      <div class="flow__header-text df-header-text">Customer Detail</div>
      <div class="flow__progress">
        <ProgressBar class="flow__progress-bar" size="100%" :value="1" :rounded="false" />
      </div>
    </header>
    <div class="customer-detail__actions">
      <div class="customer-detail__navigation">
        <router-link class="customer-detail__navigation-link" to="/admin/dashboard"> Dashboard </router-link>
        <router-link class="customer-detail__navigation-link" to="/signout">Logout</router-link>
      </div>
    </div>
    <!-- tab system -->
    <section class="tabs">
      <div class="tabs__buttons-wrapper">
        <button
          :class="showSection === item.title ? 'active' : ''"
          class="tabs__button"
          v-for="(item, index) in tabs"
          :key="item.title + index"
          @click="showTab(item.title)"
        >
          {{ item.title }}
        </button>
      </div>
      <div class="tabs__content-wrapper" v-for="(item, index) in tabs" :key="item.component + index">
        <div class="tabs__section" v-if="showSection === item.title">
          <component
            :is="item.component"
            v-bind="currentProps(item.component)"
            @loading="handleLoading"
            @newMarker="updateMarker"
          />
        </div>
      </div>
    </section>
    <div class="customer-detail__loading-overlay" v-if="loadingSection">
      <v-progress-circular class="customer-detail__spinner" indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import AdminDecision from '@/components/admin/AdminDecision.vue'
import AdminOverview from '@/components/admin/AdminOverview.vue'
import AdminEdit from '@/components/admin/AdminEdit.vue'
import AdminHistory from '@/components/admin/AdminHistory.vue'
import AdminMarker from '@/components/admin/AdminMarker'
import AdminNotes from '@/components/admin/AdminNotes.vue'
import AdminPolicy from '@/components/admin/AdminPolicy.vue'
import AdminQuote from '@/components/admin/AdminQuote.vue'
import AdminVendors from '@/components/admin/AdminVendors.vue'
import DFButton from '@/components/DFButton'
import ProgressBar from '@/components/ProgressBar.vue'
import { mapActions, mapGetters } from 'vuex'
import { InterviewFlagType, InterviewFlagCause, QuestionKey as QK } from '@/api/Interview'
import { formatCurrency } from '@/utils/number-formatter.js'
import { removeUnderscores } from '@/utils/helpers'
import { getAuth } from 'firebase/auth'

export default {
  name: 'customer-detail',
  components: {
    AdminDecision,
    AdminEdit,
    AdminHistory,
    AdminMarker,
    AdminOverview,
    AdminNotes,
    AdminPolicy,
    AdminQuote,
    AdminVendors,
    DFButton,
    ProgressBar
  },
  data() {
    return {
      QK: QK,
      accountId: '',
      accountEmail: '',
      agent: {},
      detail: {},
      errors: {},
      id: '',
      loading: false,
      loadingSection: true,
      marker: undefined,
      name: '',
      policy: {},
      question: {},
      questions: [],
      quote: {},
      riskItems: [
        { label: 'Class 1', value: 'ONE' },
        { label: 'Class 2', value: 'TWO' },
        { label: 'Class 3', value: 'THREE' },
        { label: 'Class 4', value: 'FOUR' },
        { label: 'Class 5', value: 'FIVE' },
        { label: 'Class 6', value: 'SIX' },
        { label: 'Ineligible', value: 'INELIGIBLE' }
      ],
      sections: [],
      showLoader: false,
      showPolicy: false,
      showSection: 'Overview',
      status: undefined,
      interactions: [],
      unansweredQuestions: [],
      tabs: [
        { title: 'Overview', component: 'AdminOverview' },
        { title: 'Vendors', component: 'AdminVendors' },
        { title: 'Quote', component: 'AdminQuote' },
        { title: 'Policy', component: 'AdminPolicy' },
        { title: 'Edit', component: 'AdminEdit' },
        { title: 'History', component: 'AdminHistory' },
        { title: 'Decision', component: 'AdminDecision' },
        { title: 'Marker', component: 'AdminMarker' }
        // { title: 'Notes', component: 'AdminNotes' }
      ],
      values: {}
    }
  },
  computed: {
    ...mapGetters('interview', ['hasSavedInterview', 'interviewID', 'mapToAnswers', 'answers']),
    showApprove() {
      if (
        this.policy &&
        'flags' in this.policy &&
        this.policy.flags !== null &&
        this.policy.flags.includes('NEEDS_APPROVAL')
      ) {
        return true
      }
      return false
    },
    showResolveDuplicates() {
      if (this.detail && this.detail.interview) {
        for (const flag of this.detail.interview.flags) {
          if (
            flag.type === InterviewFlagType.FROZEN &&
            (flag.cause === InterviewFlagCause.EXISTING_FINGERPRINT ||
              flag.cause === InterviewFlagCause.MULTIPLE_ACCOUNTS)
          ) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions('interview', ['updateInterviewAnswers', 'fetchInterviewQuote']),
    ...mapActions('admin', [
      'getInterviewDetail',
      'approvePolicy',
      'declinePolicy',
      'resolveDuplicates',
      'getPDFDownloadURL',
      'checkVerified',
      'getAgent',
      'fetchInterviewMarkers',
      'updateEmail'
    ]),
    ...mapActions('policy', ['getPolicy']),
    updateMarker(marker) {
      this.marker = marker
    },
    removeUnderscores,
    async handleLoading() {
      this.loadingSection = true
      this.detail = await this.getInterviewDetail({ id: this.id })
      const { quote } = await this.fetchInterviewQuote({
        id: this.id
      })
      this.unansweredQuestions = this.detail.questions
      this.quote = quote
      this.sections = this.detail.interview.sections
      this.loadingSection = false
    },
    currentProps(component) {
      switch (component) {
        case 'AdminOverview':
          return {
            name: this.name,
            interview: this.detail.interview,
            accountId: this.accountId,
            accountEmail: this.accountEmail,
            agent: this.agent,
            id: this.id,
            applicant: this.applicant,
            marker: this.marker,
            updateEmail: this.saveEmail
          }
        case 'AdminQuote':
          return {
            id: this.id,
            interview: this.detail.interview,
          }
        case 'AdminPolicy':
          return { policy: this.policy }
        case 'AdminEdit':
          return {
            interview: this.detail.interview,
            sections: this.sections,
            unanswered: this.unansweredQuestions,
            id: this.id,
            status: this.status,
            policyId: this.detail.interview.policyId
          }
        case 'AdminDecision':
          return {
            accountId: this.accountId,
            getFullInterview: this.getFullInterview,
            handleInputChange: this.handleInputChange,
            id: this.id,
            interview: this.detail.interview,
            onPolicyApproval: this.onPolicyApproval,
            riskItems: this.riskItems,
            quote: this.quote,
            status: this.status,
            policyId: this.detail.interview.policyId
          }
        case 'AdminVendors':
          return {
            orders: this.detail.interview.orders,
            interviewID: this.detail.interview.id,
            interview: this.detail.interview
          }
        case 'AdminMarker':
          return {
            id: this.id,
            currentMarker: this.marker
          }
        case 'AdminNotes':
          return {}
        default:
          return {}
      }
    },
    showTab(item) {
      this.showSection = item
    },
    async getAgentInfo() {
      const interview = this.detail.interview
      if (interview && interview?.agentId) {
        const agentId = interview.agentId
        this.agent = await this.getAgent({ id: agentId })
      }
    },
    getCurrency(val) {
      return formatCurrency(val)
    },
    async saveProgress(vals, questionKey) {
      try {
        this.loadingSection = true
        await this.updateInterviewAnswers({
          id: this.id,
          answers: [{ questionKey: questionKey, values: vals }]
        })

        this.detail = await this.getInterviewDetail({ id: this.id })
        this.unansweredQuestions = this.detail.questions
        this.loadingSection = false
      } catch (e) {
        this.loadingSection = false
        // eslint-disable-next-line no-console
        console.warn("We couldn't save the current step")
      }
    },
    async getFullInterview() {
      if (!this.loading) {
        this.loading = true
        this.detail = await this.getInterviewDetail({ id: this.id })
        this.accountEmail = this.detail?.interview?.account?.email
        const { quote } = await this.fetchInterviewQuote({
          id: this.id
        })

        this.unansweredQuestions = this.detail.questions
        this.quote = quote
        const marker = await this.fetchInterviewMarkers({ id: this.id })
        this.marker = marker

        await this.checkVerified({ id: this.accountId })

        await this.getAgentInfo()

        if (this.detail.interview.policyId !== null) {
          this.policy = await this.getPolicy(this.detail.interview.policyId)
          this.showPolicy = true
        }

        const { status, sections, applicant } = this.detail.interview

        this.status = status
        this.applicant = applicant
        this.sections = sections
        this.loading = false
        this.loadingSection = false
      }
    },
    async onPolicyApproval() {
      this.loadingSection = true
      const { policyId } = this.detail.interview
      await this.approvePolicy({ id: policyId, approve: true })
      this.policy = await this.getPolicy(this.detail.interview.policyId)
      window.location.reload()
      this.loadingSection = false
    },
    async handleInputChange(evt) {
      this.loading = true
      const { policyId } = this.detail.interview
      await this.declinePolicy({
        id: policyId,
        approve: false,
        interviewId: this.id,
        riskScore: { class: evt }
      })
      this.loading = false
    },
    async saveEmail(firstName, lastName, phoneNumber, email) {
      const { updateAccount, error } = await this.updateEmail({
        id: this.accountId,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email
      })
      if (error) return { error }
      this.accountEmail = updateAccount.email
      return { email: this.accountEmail }
    },
    async updateUnansweredQuestions() {}
  },
  mounted() {
    const auth = getAuth()
    auth.currentUser.getIdToken(true)
  },
  async created() {
    this.name = this.$route.params.name
    this.id = this.$route.params.id
    this.accountId = this.$route.params.accountId
    await this.getFullInterview()
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';
.admin-container {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  font-size: 14px;
  margin-top: 20px;
}

.basic-info,
.flags,
.interactions,
.admin-related {
  margin-bottom: 1rem;
}
.customer-detail {
  padding-bottom: 5rem;
  p {
    line-height: 23px;
  }
  .tabs {
    &__buttons-wrapper {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    &__button {
      flex: 1;
      border: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 14px;
      font-weight: bold;
      padding: 10px 0;
      &:focus {
        outline: none;
      }
      &.active {
        color: $c-eggshell;
        background-color: $c-red;
      }
    }
  }

  .no-data {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    min-height: 60vh;
    h1 {
      text-align: center;
    }
  }

  .flow__progress {
    display: flex;
    flex-direction: column;
    height: 15px;
    justify-content: flex-end;
    margin-top: 1rem;

    &-bar {
      height: 5px !important;
    }
  }

  h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: $c-red;
    margin-bottom: 10px;
    padding: 10px 0 5px;
  }

  li {
    list-style-type: decimal;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    min-height: 30px;
    margin-top: 0.5em;
    margin-bottom: 2.5em;

    &-content {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__decline-button {
    font-size: 14px;
    label {
      font-weight: bold;
    }
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
  }
  &__navigation-link {
    margin-left: 20px;
  }

  &__flags-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    font-size: 14px;
    column-gap: 10px;
    row-gap: 10px;
    div {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 5px;
    }
  }
  &__detail {
    padding: 0 0 1rem;
    margin-bottom: 2rem;
    h1 {
      color: $c-red;
      display: block;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
  }
  &__spinner {
    margin: 0 0 2em 0;
  }
  &__section-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
  }
  &__section-header {
    margin-bottom: 2rem;
    width: 100%;
    text-align: left;
    color: $c-red;
    border-bottom: 5px solid $c-red;
  }
  &__section-questions {
    display: flex;
    flex-direction: column;
  }
  &__section-question {
    flex: 1;
  }
  &__loading-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__resolve-btn {
    padding-top: 1rem;
  }
  &__approve-btn {
    padding-top: 1rem;
  }
  &__spinner {
    flex: 1;
  }

  &__quote-detail,
  &__policy-detail {
    padding-top: 1rem;
  }
  &__policy-detail {
    padding-bottom: 2rem;
  }
  .risk-score-dropdown {
    margin-top: 20px;
  }
}
</style>
