import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import authModule from './modules/AuthModule'
import accountModule from './modules/AccountModule'
import interviewModule from './modules/InterviewModule'
import policyModule from './modules/PolicyModule'
import adminModule from './modules/AdminModule'
import billingModule from './modules/BillingModule'
import { apolloClient } from '@/config/apollo.config'
import contentModule from './modules/ContentModule'
import searchModule from '@/store/modules/SearchModule.js'
import analyticsModule from './modules/AnalyticsModule'
import imoModule from './modules/IMOModule.js'

Vue.use(Vuex)

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['interview']
})

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['backendAnalytics']
})

const store = new Vuex.Store({
  state: {
    apolloClient
  },
  modules: {
    auth: authModule,
    account: accountModule,
    content: contentModule,
    interview: interviewModule,
    policy: policyModule,
    admin: adminModule,
    billing: billingModule,
    search: searchModule,
    backendAnalytics: analyticsModule,
    imo: imoModule
  },
  plugins: [vuexSession.plugin, vuexLocal.plugin]
})

// check for a "token" querystring arg, and if found, configure our access token in the authModule
if (location.search.indexOf('token=') > -1) {
  const qs = location.search.substr(0, 1) === '?' ? location.search.substr(1) : location.search
  const params = qs.split('&').reduce((obj, param) => {
    const [k, v] = param.split('=')
    if (v !== undefined) {
      obj[k] = v
    }
    return obj
  }, {})

  if (params.hasOwnProperty('token')) {
    store.dispatch('auth/setAccessToken', params.token)
  }
}

export default store
