import gql from 'graphql-tag'

export default {
  SetInterviewMarker: (id, name, metadata) => ({
    mutation: gql`
      mutation AddInterviewMarker($id: String!, $name: String!, $metadata: [MetadataInput!]) {
        addInterviewMarker(id: $id, name: $name, metadata: $metadata)
      }
    `,
    variables: {
      id,
      name,
      metadata
    }
  }),
  FetchInterviewMarkers: (id) => ({
    query: gql`
      query GetInterviewMarkers($id: String!) {
        getInterviewMarkers(id: $id) {
          name
          metadata {
            key
            value
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  AddManualIDVerificationFlag: (id) => ({
    mutation: gql`
      mutation VerifyID($id: String!) {
        addManualIDVerificationFlag(id: $id) {
          success
          message
        }
        interview(id: $id, update: {}) {
          interview {
            flags {
              type
              source
              description
            }
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  ResolveSpousalSignature: (id) => ({
    mutation: gql`
      mutation ResolveSpousalSignature($id: String!) {
        resolveInterviewFlags(id: $id, where: { cause: "SPOUSAL_SIGNATURE_REQUIRED" }) {
          error {
            type
            message
          }
          interview {
            flags {
              type
              source
              description
            }
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  ClaretoSearch: (id) => ({
    mutation: gql`
      mutation ClaretoSearch($id: String!) {
        claretoSearch(id: $id) {
          id
          searchId
          message
        }
      }
    `,
    variables: {
      id
    }
  }),
  ExpireIrixOrder: (id) => ({
    mutation: gql`
      mutation ExpireIrixOrder($id: String!) {
        expireIrixOrder(interviewID: $id) {
          success
          error {
            message
          }
        }
        interview(id: $id, update: {}) {
          interview {
            orders {
              irix {
                orderId
                status
                created
                updated
                trackingId
                riskScore
                riskScoreRx
                resultStatus
                classResults {
                  index
                  level
                  identifier
                }
                ruleHits {
                  groupIdent
                  subGroupIdent
                  ident
                  modifier
                  name
                }
                applicant {
                  ssn
                  firstName
                  lastName
                  gender
                  dob
                  state
                  zipCode
                  relationship
                  middleName
                  signatureType
                }
                attributes {
                  name
                  value
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      id
    }
  }),

  RegeneratePolicyPacket: (id) => ({
    mutation: gql`
      mutation RegeneratePacket($id: String!) {
        regeneratePacket(id: $id) {
          success
          message
          url
        }
      }
    `,
    variables: {
      id
    }
  }),

  ExpireInstantIDOrder: (id) => ({
    mutation: gql`
      mutation ExpireInstantIDOrder($id: String!) {
        expireInstantIDOrder(interviewID: $id) {
          success
          error {
            message
          }
        }
        interview(id: $id, update: {}) {
          interview {
            orders {
              instantId {
                status
                orderId
                created
                updated
                quoteback
                cvi
                reportURN
                birthDate
              }
            }
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  ExpireMVROrder: (id) => ({
    mutation: gql`
      mutation ExpireMVROrder($id: String!) {
        expireMVROrder(interviewID: $id) {
          success
          error {
            message
          }
        }
        interview(id: $id, update: {}) {
          interview {
            orders {
              mvr {
                status
                orderId
                created
                updated
                quoteback
                reportURN
                isHit
                licenses {
                  number
                  state
                  class
                  issuedDate
                  expirationDate
                  restrictions
                  isLicensed
                  isNotLicensed
                  isActive
                  isExpired
                  isRevoked
                  isSuspended
                  isDeceased
                }
                violations {
                  svcCode
                  date
                  yearsAgo
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  GetSignedAuthorizationForm: (interviewID) => ({
    query: gql`
      query AuthForm($interviewID: String!) {
        authorizationDownloadURL(interviewID: $interviewID)
      }
    `,
    variables: {
      interviewID
    }
  }),
  GetClaretoSearchRecords: (id) => ({
    query: gql`
      query GetClaretoSearchRecords($id: String!) {
        claretoSearchRecords(id: $id) {
          searchRecords {
            interviewId
            searchId
            accountNumber
            created
            updated
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  GetAgent: (id) => ({
    query: gql`
      query GetAgent($id: String!) {
        agent(id: $id) {
          id
          agencyId
          accountId
          agentRole
          agentEmail
        }
      }
    `,
    variables: {
      id
    }
  }),
  CheckUserValidated: (id) => ({
    query: gql`
      query CheckUserValidated($id: String!) {
        account(id: $id) {
          identities {
            id
            name
          }
        }
      }
    `,
    variables: {
      id
    }
  }),
  QuoteOverride: (id, override) => ({
    query: gql`
      query OverrideQuote($id: String!, $override: QuoteOverride) {
        quote(interviewID: $id, override: $override) {
          success
          reason
          quote {
            benefitAmount
            benefitAmountOptions
            benefitType
            benefitLimit {
              minimum
              maximum
            }
            dayOneCoverage
            hasPremium
            premium
            premiumPer1kIncome
            premiumPer1YearExtension
            rate
            employment {
              code
              description
            }
            annualBenefit
            annualIncome
            termRecommended
            hasOverride
            termEndDate
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termLimit {
              minimum
              maximum
            }
            youngestChildAgeLimit {
              minimum
              maximum
            }
            hasChild
            youngestChildAge
            insuredAge
            insuredAgeLimit {
              minimum
              maximum
            }
            isEstimate
            childExtensionAvailable
            childExtensionMaxAge
            childExtensionMaxYearsRemaining
            subscriptionFee
            incomeRiderMaxAnnualIncome
            incomeRiderMaxTotalBenefit
          }
        }
      }
    `,
    variables: {
      id: id,
      override: override
    }
  }),
  GetPDFLink: (id) => ({
    query: gql`
      query getPDFLink($id: String!) {
        orderReportDownloadURL(reportURN: $id)
      }
    `,
    variables: {
      id: id
    }
  }),
  ResolveDuplicates: (id) => ({
    mutation: gql`
      mutation ResolveDuplicates($id: String!) {
        resolveDuplicates(id: $id)
      }
    `,
    variables: {
      id: id
    }
  }),

  PolicyDenial: (id, approve) => ({
    mutation: gql`
      mutation PolicyDenial($id: String!, $approve: Boolean, $interviewId: String!, $riskScore: RiskScoreInput) {
        policy(id: $id, approve: $approve) {
          id
          status
          flags
        }
        copyInterview(id: $interviewId, riskScore: $riskScore) {
          id
          status
          riskScore {
            class
          }
        }
      }
    `,
    variables: {
      id: id,
      approve: approve
    }
  }),

  PolicyApproval: (id, approve) => ({
    mutation: gql`
      mutation PolicyApproval($id: String!, $approve: Boolean) {
        policy(id: $id, approve: $approve) {
          id
          status
          flags
        }
      }
    `,
    variables: {
      id: id,
      approve: approve
    }
  }),

  FlagIncorrectPolicyPacket: (id, interviewID) => ({
    mutation: gql`
      mutation FlagIncorrectPacket($id: String, $interviewID: String) {
        flagIncorrectPacket(id: $id, interviewID: $interviewID) {
          id
          status
          flags
          number
        }
      }
    `,
    variables: {
      id: id,
      interviewID: interviewID
    }
  }),

  UnflagPolicyPacket: (id, interviewID) => ({
    mutation: gql`
      mutation UnflagPacket($id: String, $interviewID: String) {
        unflagPacket(id: $id, interviewID: $interviewID) {
          id
          status
          flags
          number
        }
      }
    `,
    variables: {
      id: id,
      interviewID: interviewID
    }
  }),
  ChangeIssuer: (id, product) => ({
    mutation: gql`
      mutation ChangeIssuer($id: String!, $product: ProductInput) {
        copyInterviewChangeIssuer(id: $id, prd: $product) {
          id
          status
          accountId
        }
      }
    `,
    variables: {
      id: id,
      product: product
    }
  }),
  Interview: (id, update) => ({
    mutation: gql`
      mutation Interview($id: String!, $update: UpdateInterviewInput!) {
        interview(id: $id, update: $update) {
          error {
            message
            type
          }
          interview {
            status
            riskScore {
              class
              flags
              flagDescriptions
              info
              origin
            }
            showQuote
            quoteDisplayMode
          }
        }
      }
    `,
    variables: {
      id: id,
      update: update,
    }
  }),
  getAllQuestions: (id) => ({
    query: gql`
      query getAllQuestions($id: String!) {
        interview(id: $id) {
          id
          policyId
          agentId
          status
          showQuote
          quoteDisplayMode
          product {
            vendor
            variant
          }
          applicant {
            address {
              state
            }
          }
          questions {
            concerns
            label
            key
            text
            active
            answers
            answer {
              familyHistory {
                diagnoses
                severity
                relationship
              }
            }
            answered
            required
            valueType
            multipleChoice
            options {
              selected
              text
              value
            }
          }
          quote {
            benefitAmount
            benefitType
            benefitLimit {
              minimum
              maximum
            }
            dayOneCoverage
            benefitAmountOptions
            hasPremium
            premium
            premiumPer1kIncome
            premiumPer1YearExtension
            rate
            employment {
              code
              description
            }
            annualBenefit
            annualIncome
            termRecommended
            hasOverride
            locked
            termEndDate
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termLimit {
              minimum
              maximum
            }
            youngestChildAgeLimit {
              minimum
              maximum
            }
            hasChild
            youngestChildAge
            insuredAge
            insuredAgeLimit {
              minimum
              maximum
            }
            isEstimate
            childExtensionAvailable
            childExtensionMaxAge
            subscriptionFee
            incomeRiderMaxAnnualIncome
            incomeRiderMaxTotalBenefit
            naarEHRThreshold
            naarThreshold
            naarValue
            selectedNaarValue
          }
          currentSection {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
          quoteHistory {
            time
            quote {
              benefitAmount
              benefitType
              benefitLimit {
                minimum
                maximum
              }
              benefitAmountOptions
              dayOneCoverage
              hasPremium
              premium
              premiumPer1kIncome
              premiumPer1YearExtension
              rate
              employment {
                code
                description
              }
              annualBenefit
              annualIncome
              termRecommended
              hasOverride
              locked
              termEndDate
              termReason {
                code
                description
                untilChildAge
                untilInsuredAge
              }
              termLimit {
                minimum
                maximum
              }
              youngestChildAgeLimit {
                minimum
                maximum
              }
              hasChild
              youngestChildAge
              insuredAge
              insuredAgeLimit {
                minimum
                maximum
              }
              isEstimate
              childExtensionAvailable
              childExtensionMaxAge
              subscriptionFee
              incomeRiderMaxAnnualIncome
              incomeRiderMaxTotalBenefit
              naarEHRThreshold
              naarThreshold
              naarValue
              selectedNaarValue
            }
          }
          riskScoreHistory {
            time
            score {
              class
              flags
              flagDescriptions
              info
              origin
              description
              subscores {
                class
                flags
                flagDescriptions
                info
                origin
                description
                subscores {
                  class
                  flags
                  flagDescriptions
                  info
                  origin
                  description
                }
                reasons
                ruleHits {
                  name
                  key
                  concerns
                }
              }
              reasons
              ruleHits {
                name
                key
                concerns
              }
            }
          }
          account {
            id
            firstName
            lastName
            email
            identities {
              id
              name
              type
            }
            interviews {
              id
              fullName
              firstName
              lastName
              created
              status
              updated
            }
          }
          fingerprintGroup {
            fingerprint
            interviewSummaries {
              interviewID
              applicant {
                accountID
                fullName
                firstName
                lastName
              }
              created
              status
              updated
            }
          }
          applicant {
            accountID
            age
            bmi
            fullName
            emailAddress
            dateOfBirth
            phoneNumbers {
              number
              type
            }
          }
          interactions {
            type
            time
          }
          answerHistory {
            time
            answer {
              values
              key
              author {
                displayName
              }
            }
          }
          statusHistory {
            time
            status
          }
          flags {
            type
            description
            cause
            source
            name
            created
            resolved
          }
          riskScore {
            class
            flags
            flagDescriptions
            info
            origin
            reasons
            subscores {
              class
              flags
              description
              info
              origin
              reasons
              flagDescriptions
              ruleHits {
                name
                concerns
              }
            }
          }
          orders {
            instantId {
              orderId
              status
              created
              updated
              quoteback
              cvi
              reportURN
              birthDate
            }
            prefill {
              orderId
              status
              created
              updated
              quoteback
              firstName
              middleInitial
              lastName
              address {
                line1
                line2
                city
                state
                postalCode
              }
              birthDate
              gender
              ssn
              maritalStatus
              licenseNumber
              licenseState
              emailAddresses
              phoneNumbers
              householdMembers {
                firstName
                middleInitial
                lastName
                birthDate
                gender
                ssn
              }
              inputs
            }
            mvr {
              orderId
              status
              created
              updated
              quoteback
              isHit
              reportURN
              licenses {
                number
                state
                class
                issuedDate
                expirationDate
                restrictions
                isLicensed
                isNotLicensed
                isActive
                isExpired
                isRevoked
                isSuspended
                isDeceased
              }
              violations {
                svcCode
                date
                yearsAgo
              }
            }
            irix {
              orderId
              status
              created
              updated
              trackingId
              riskScore
              riskScoreRx
              resultStatus
              classResults {
                index
                level
                identifier
              }
              ruleHits {
                groupIdent
                subGroupIdent
                ident
                modifier
                name
              }
              applicant {
                ssn
                firstName
                lastName
                gender
                dob
                state
                zipCode
                relationship
                middleName
                signatureType
              }
              attributes {
                name
                value
              }
            }
            healthPiqture {
              orderId
              status
              created
              updated
              riskScore
              riskScoreStatus
            }
          }
          sections {
            id
            category
            title
            active
            incomplete
            displayOptions
            hasQuestions
            questions {
              allowsNone
              concerns
              label
              key
              text
              active
              answers
              answer {
                familyHistory {
                  diagnoses
                  severity
                  relationship
                }
              }
              answered
              required
              valueType
              multipleChoice
              options {
                selected
                text
                value
              }
              reflexives {
                category
                isSatisfied
                target {
                  label
                  multipleChoice
                  allowsNone
                  defaultCategory
                  valueType
                  placeholder
                  required
                  active
                  key
                  text
                  answers
                  answer {
                    familyHistory {
                      diagnoses
                      severity
                      relationship
                    }
                  }
                  answered
                  options {
                    selected
                    text
                    value
                  }
                  reflexives {
                    category
                    isSatisfied
                    target {
                      concerns
                      label
                      multipleChoice
                      allowsNone
                      defaultCategory
                      valueType
                      placeholder
                      required
                      active
                      key
                      text
                      answers
                      answer {
                        familyHistory {
                          diagnoses
                          severity
                          relationship
                        }
                      }
                      options {
                        selected
                        text
                        value
                      }
                      reflexives {
                        category
                        isSatisfied
                        target {
                          concerns
                          label
                          multipleChoice
                          allowsNone
                          defaultCategory
                          valueType
                          placeholder
                          required
                          active
                          key
                          text
                          answers
                          answered
                          reflexives {
                            category
                            isSatisfied
                            target {
                              concerns
                              label
                              multipleChoice
                              allowsNone
                              defaultCategory
                              valueType
                              placeholder
                              required
                              active
                              key
                              text
                              answers
                              answer {
                                familyHistory {
                                  diagnoses
                                  severity
                                  relationship
                                }
                              }
                              options {
                                selected
                                text
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        questions(interviewID: $id, where: { remaining: true }) {
          key
          text
          defaultCategory
        }
      }
    `,
    variables: {
      id: id
    }
  }),
  querySupportedStates: () => ({
    query: gql`
      query {
        supportedStates {
          code
          label
        }
      }
    `
  }),
  mutateAccounEmail: (id, email) => ({
    mutation: gql`
      mutation updateAccount($id: String!, $email: String) {
        adminUpdateAccount(id: $id, email: $email) {
          email
        }
      }
    `,
    variables: {
      id,
      email
    }
  }),
  mutateUpdateAccount: (id, firstName, lastName, phoneNumber, email) => ({
    mutation: gql`
      mutation updateAccount(
        $id: String!
        $firstName: String!
        $lastName: String!
        $phoneNumber: String!
        $email: String!
      ) {
        updateAccount(id: $id, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, email: $email) {
          id
          firstName
          lastName
          email
          identities {
            type
            name
          }
        }
      }
    `,
    variables: {
      id,
      firstName,
      lastName,
      phoneNumber,
      email
    }
  })
}
