<template>
  <div class="interview-initial">
    <FlowWrapper
      :progress="1 / 8"
      :showProgress="false"
      :showActions="false"
      :can-move-to-next="hasValidFormData"
      :answer-values="values"
      :on-next="onNext"
      :before-next="beforeNext"
      @result="onSaveResult"
      :is-next-loading="isProcessing"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:sub-content>
        <ol class="sub-content-list">
          <li>☝️ Complete our simple online interview</li>
          <li>👍️ Select from your personalized policy options & prices</li>
          <li>✒️ Sign & submit your application for the coverage you want</li>
          <li>🗓️️ If approved, buy your coverage as soon as today!</li>
        </ol>
      </template>
      <template v-slot:content-header>
        <div>To get started, create an account to keep your info secure.</div>
      </template>
      <template v-slot:content>
        <div class="initial-step__content">
          <div class="initial-step__name-entry">
            <LabeledHolder :label="LABELS.FIRST_NAME">
              <v-text-field
                :rules="[isRequired()]"
                validate-on-blur
                v-model="values[QK.FIRST_NAME]"
                :error="errors[QK.FIRST_NAME]"
              />
            </LabeledHolder>
            <LabeledHolder :label="LABELS.MIDDLE_INITIAL" tooltip="Middle initial">
              <v-text-field v-model="values[QK.MIDDLE_INITIAL]" :error="errors[QK.MIDDLE_INITIAL]" />
            </LabeledHolder>
            <LabeledHolder :label="LABELS.LAST_NAME" class="initial-step__last-name">
              <v-text-field
                :rules="[isRequired()]"
                validate-on-blur
                v-model="values[QK.LAST_NAME]"
                :error="errors[QK.LAST_NAME]"
              />
            </LabeledHolder>
          </div>

          <div class="initial-step__contact-info">
            <LabeledHolder :label="LABELS.MOBILE">
              <v-text-field
                prefix="+1"
                type="tel"
                :rules="[isRequired(), isPhoneNumber()]"
                validate-on-blur
                v-mask="'###-###-####'"
                v-model="values[QK.MOBILE_NUMBER]"
                :error="errors[QK.MOBILE_NUMBER] !== undefined"
                :error-messages="errors[QK.MOBILE_NUMBER]"
                class="initial-step__mobile-entry"
              />
            </LabeledHolder>
            <LabeledHolder :label="LABELS.EMAIL">
              <v-text-field
                type="email"
                :rules="[isRequired(), isEmail()]"
                validate-on-blur
                v-model="values[QK.EMAIL_ADDRESS]"
                :error="errors[QK.EMAIL_ADDRESS]"
              />
            </LabeledHolder>
          </div>

          <div class="initial-step__state-section">
            <LabeledHolder :label="LABELS.STATE" class="initial-step__state">
              <v-select
                :items="supportedStates"
                item-text="name"
                item-value="abbreviation"
                v-model="state"
                class="initial-step__state"
                :rules="[isRequired()]"
                validate-on-blur
              >
              </v-select>
            </LabeledHolder>
          </div>

          <v-checkbox hide-details v-model="acceptedNotices" class="initial-step__consent-box" @change="changed">
            <template v-slot:label>
              <div class="initial-notices">
                I am the person indicated above and I consent to doing business electronically; have read and agree to
                the
                <a href="#" @click.prevent="showModal($event, 'isShowingConsent')"
                  >Use of Electronic Signatures and Delivery</a
                >, <a href="#" @click.prevent="showModal($event, 'isShowingCPN')"> Notices</a>,
                <a href="#" @click.prevent="showModal($event, 'isShowingTerms')">Terms of Use</a>, and
                <a href="#" @click.prevent="showModal($event, 'isShowingPPolicy')">Privacy Policy</a>; and have read and
                agree to electronically signing the
                <a href="#" @click.prevent="showModal($event, 'isShowingAuthorizations')">Authorizations</a>.
              </div>
            </template>
          </v-checkbox>

          <div class="legal-consent">
            By clicking Next, you give your contact information directly to Dayforward and also give your signed
            permission for Dayforward and any of its affiliates, employees or representatives to contact you for
            marketing purposes at the telephone number provided, including via auto-dialed text message and live,
            automated and pre-recorded telephone calls, even if your number is on a Do Not Call list. Your consent to
            receive text messages and telephone calls is not a condition to purchase any products or services from or
            through Dayforward (just call us to apply).
          </div>
        </div>
      </template>
    </FlowWrapper>

    <!-- modals -->

    <Modal
      title="Consent to Doing Business Electronically, Use of Electronic Signatures, and Electronic Delivery of Communications."
      :showActions="false"
      :show-print-link="true"
      v-model="isShowingConsent"
      @close="isShowingCPN = false"
      :pdf-url="url"
    >
      <template v-slot:content>
        <ElectronicConsent @pdfURLGenerated="setURL" />
      </template>
    </Modal>

    <Modal
      title="Notices."
      :showActions="false"
      v-model="isShowingCPN"
      @close="isShowingCPN = false"
      :show-print-link="true"
      :pdf-url="url"
    >
      <template v-slot:content>
        <ConsumerProtectionNotice @pdfURLGenerated="setURL" />
      </template>
    </Modal>
    <Modal
      title="Authorization to Obtain and Disclose Information."
      :showActions="false"
      v-model="isShowingAuthorizations"
      @close="isShowingAuthorizations = false"
      :show-print-link="true"
      :pdf-url="url"
    >
      <template v-slot:content>
        <Authorizations @pdfURLGenerated="setURL" />
      </template>
    </Modal>
    <Modal title="Terms of Use." :showActions="false" v-model="isShowingTerms" @close="isShowingTerms = false">
      <template v-slot:content>
        <Terms />
      </template>
    </Modal>
    <Modal title="Privacy Policy." :showActions="false" v-model="isShowingPPolicy" @close="isShowingPPolicy = false">
      <template v-slot:content>
        <PrivacyPolicy />
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import Colors from '@/assets/styles/_colors.scss'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { InterviewFlagCause, QuestionKey as QK } from '@/api/Interview'
import { inputId } from '@/utils/inputs'
import { AllowedTypes } from '@/utils/keysAllowed-directive.js'
import { Terms, ConsumerProtectionNotice, Authorizations, PrivacyPolicy, ElectronicConsent } from '@/components/modals'
import { RouteName } from '@/router/routes/constants.js'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import { isEmail, isPhoneNumber, isRequired } from '@/rules'
import { VueMaskDirective } from 'v-mask'
import { SupportedStatesArr, SupportedStatesArrWithArizona } from '@/utils/states.js'
import { getCookie } from '@/utils/helpers'
import { LeadSource } from '@/api/BackendAnalytics'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import gql from 'graphql-tag'
import { apolloClient } from '@/config/apollo.config'
import { AccountStatus } from '@/api/Interview/constants'

// graphql queries
const validateQuery = gql`
  query AccountInfo($email: String) {
    accountInfo(email: $email) {
      exists
    }
  }
`
const createMutation = gql`
  mutation CreateAccount($email: String!, $phoneNumber: String) {
    createAccount(email: $email, phoneNumber: $phoneNumber) {
      id
      email
      customToken
    }
  }
`

const LABELS = {
  FIRST_NAME: 'First name',
  MIDDLE_INITIAL: 'M.',
  LAST_NAME: 'Last name',
  MOBILE: 'Mobile #',
  EMAIL: 'Email',
  STATE: 'State'
}
export default {
  name: 'InterviewInitialStep',
  components: {
    Authorizations,
    ConsumerProtectionNotice,
    ElectronicConsent,
    FlowWrapper,
    Modal,
    PrivacyPolicy,
    Terms,
    LabeledHolder
  },
  directives: { mask: VueMaskDirective },
  data() {
    return {
      AllowedTypes,
      LABELS,
      ORF: InterviewFlagCause,
      QK,
      STEP_NAME: 'initial',
      acceptedNotices: undefined,
      accountStatus: undefined,
      errors: {},
      imoAgent: undefined,
      interstitialVariant: '0',
      isCreatingInterview: false,
      isSaving: false,
      isShowingAuthorizations: false,
      isShowingCPN: false,
      isShowingConsent: false,
      isShowingPPolicy: false,
      isShowingTerms: false, // show / hide the T&C modal,
      isProcessing: false, // is processing the data
      url: '',
      values: {},
      state: undefined,
      account: undefined,
      hasAccount: false
    }
  },
  computed: {
    ...mapState({
      intId: (state) => state.interview.interview.id,
      accId: (state) => state.interview.accountId
    }),
    ...mapGetters('interview', [
      'answers',
      'answersForKeys',
      'questionsMap',
      'interview',
      'parseBool',
      'mapToAnswers',
      'hasSavedInterview',
      'enteredProductVariant',
      'tempInterviewData',
      'interviewID'
    ]),
    ...mapGetters('backendAnalytics', ['userID']),
    ...mapGetters('auth', ['isAuthenticated']),
    Colors() {
      return Colors
    },
    supportedStates() {
      const enabled = this.$flagsmith?.hasFeature('arizona_release')
      return enabled ? SupportedStatesArrWithArizona : SupportedStatesArr
    },
    questionKeys() {
      return [QK.FIRST_NAME, QK.MIDDLE_INITIAL, QK.LAST_NAME, QK.MOBILE_NUMBER, QK.EMAIL_ADDRESS, QK.ACCEPTED_NOTICES]
    },
    hasValidFormData() {
      return (
        !!this.values[QK.FIRST_NAME] &&
        !!this.values[QK.LAST_NAME] &&
        !!this.values[QK.MOBILE_NUMBER] &&
        !!this.values[QK.EMAIL_ADDRESS] &&
        this.acceptedNotices
      )
    }
  },
  methods: {
    ...mapActions('auth', ['sendPasswordlessAuth', 'setAccessToken']),
    ...mapActions('interview', [
      'redirectResult',
      'getIsSupportedState',
      'setUserState',
      'createInterview',
      'bindInterview',
      'setInterviewMetadata',
      'updateInterviewAnswers',
      'updateEnteredProductVariant',
      'updateTempInterviewData',
      'clearTempInterviewData',
      'bindAgentToInterview'
    ]),
    ...mapActions('account', ['createUnverifiedAccount', 'getAccount']),
    ...mapActions('imo', ['getAgent']),
    getCookie,
    isEmail,
    isPhoneNumber,
    isRequired,

    setURL(url) {
      this.url = 'http:' + url
    },

    onSaveResult(res) {
      this.errors = res.errors || {}
    },

    changed(v) {
      this.acceptedNotices = v
      this.values[QK.ACCEPTED_NOTICES] = [String(v)]
    },

    showModal(event, modal) {
      event.stopPropagation()
      this[modal] = true
    },

    async createNewInterview(accountId) {
      const interview = await this.createInterview(this.state ?? 'TX')
      this.$analytics.idempotentTrack({
        key: interview.id,
        event: 'app_started'
      })
      // eslint-disable-next-line no-console
      console.info(`created new interview. ${interview.id}`)

      await this.bindInterview({
        id: interview.id,
        accountId: accountId,
        ...(this.authReason !== undefined && { authReason: this.authReason })
      })
      this.$analytics.identify({ interviewIDs: [interview.id] })

      if (this.$route.name !== RouteName.GET_STARTED && this.$route.name !== RouteName.INTERVIEW_INITIAL) {
        await this.$router.replace({ name: RouteName.GET_STARTED })
      }
      this.$analytics.identify({ interviewIDs: [interview.id] })
      // eslint-disable-next-line no-console
      console.log('Setting interview analytics metadata ...')
      await this.setAnalyticsMetadata()
      return interview
    },

    async setAnalyticsMetadata() {
      const {
        campaign: { source }
      } = this.$analytics.getCampaign()
      const metadata = [{ key: 'CustomerIOID', value: this.userID }]
      if (source) {
        metadata.push({ key: 'Campaign', value: source })
      }
      await this.setInterviewMetadata({
        id: this.interviewID,
        metadata
      })
    },

    async createAccount() {
      let res
      const email = this.values[QK.EMAIL_ADDRESS].toLowerCase()
      try {
        res = await apolloClient.mutate({
          mutation: createMutation,
          variables: {
            email: email,
            phoneNumber: this.values[QK.MOBILE_NUMBER]
          }
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      if (res && res.data) {
        return res.data.createAccount
      }
    },
    async checkAccountStatus() {
      const email = this.values[QK.EMAIL_ADDRESS].toLowerCase()
      try {
        const validateRes = await apolloClient.query({
          query: validateQuery,
          variables: {
            email: email,
            params: ''
          }
        })
        const hasAccount = validateRes?.data?.accountInfo?.exists
        this.hasAccount = hasAccount
        return hasAccount
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return false
      }
    },

    async beforeNext() {
      this.isProcessing = true
      // First, check to see if a user has already started filling out an application. If so, redirect them to their
      // previous application.
      this.hasAccount = await this.checkAccountStatus()
      await this.getAccountStatus()
      if (this.isAuthenticated === false && this.accountStatus !== AccountStatus.HAS_NO_EXISTING_INTERVIEW) {
        window.sessionStorage.setItem('lastCategory', 'SIGN_IN_REQUIRED')
        await this.$router.push({ name: RouteName.SIGN_IN })
        this.isProcessing = false
        return { proceed: false }
      }

      // Check to see if the state that the user has selected is supported. If not, offramp them.

      let isSupported = this.state !== 'XX'
      if (!isSupported) {
        this.updateTempInterviewData(this.values)
        this.updateEnteredProductVariant(this.state)
        await this.$router.push({ name: RouteName.OR_WAITLIST })
        this.isProcessing = false
        return { proceed: false }
      }

      this.clearTempInterviewData()
      if (this.values[QK.EMAIL_ADDRESS]) {
        this.values[QK.EMAIL_ADDRESS] = this.values[QK.EMAIL_ADDRESS].toLowerCase()
      }
      return { proceed: true }
    },
    async getAccountStatus() {
      if (this.values[QK.EMAIL_ADDRESS]) {
        let redirect
        try {
          redirect = await this.redirectResult({
            email: this.values[QK.EMAIL_ADDRESS].toLowerCase()
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        this.setAccountStatus(redirect)
      }
    },
    setAccountStatus(redirect) {
      switch (redirect.redirectStatus) {
        case 0:
          this.accountStatus = AccountStatus.HAS_NO_EXISTING_INTERVIEW
          break
        case 1:
          this.accountStatus = AccountStatus.HAS_EXISTING_ACCOUNT
          break
        case 2:
          this.accountStatus = AccountStatus.HAS_EXISTING_INTERVIEW
          break
        case 3:
          this.accountStatus = AccountStatus.HAS_EXISTING_POLICY
          break
        default:
          this.accountStatus = 'SIGNED_IN'
      }
    },
    async setupAccount() {
      const auth = getAuth()
      const account = await this.createAccount()
      // STEP 3  - call `signInWithCustomToken` (Firebase) to get the firebase user
      const signInResult = await signInWithCustomToken(auth, account.customToken)
      // STEP 4  - Bind the interview? - this happens onNext
      const accessToken = signInResult.user.accessToken
      this.setAccessToken(accessToken)
      return account
    },
    async checkAgent() {
      // Check to see if customer was refered by agent
      if (this.imoAgent !== undefined) {
        try {
          // Bind agent to interview
          const agentId = await this.getAgent({ agentId: this.imoAgent })
          await this.bindAgentToInterview({
            id: this.interview.id,
            agentId: agentId
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    },
    setMarketOnInterview() {
      // Sets the state in which the policy would be issued
      this.setUserState({ userState: this.state })
      this.updateEnteredProductVariant(this.state)
    },
    async submitAnswers() {
      try {
        // updates the interview with current answers
        const updateResult = await this.updateInterviewAnswers({
          id: this.interview.id,
          answers: this.mapToAnswers(this.values, this.questionKeys)
        })
        const keyedResults = updateResult?.data?.putInterviewAnswers
        if (!keyedResults) {
          return {
            success: false,
            errors: { general: 'could not save interview data' }
          }
        }
        let hasError = false
        let errors = {}
        for (let i = 0; i < keyedResults.length; i++) {
          const obj = keyedResults[i]
          if (!obj.success) {
            hasError = true
            errors[obj.questionKey] = obj.message
          }
        }
        return {
          success: !hasError,
          errors
        }
      } catch (e) {
        return { success: false, errors: { general: e } }
      }
    },
    analyticsTracking() {
      this.$analytics.idempotentTrack({
        key: this.interview.id,
        event: 'app_contactInfoSaved',
        properties: {
          has_provided_email: !!this.values[QK.EMAIL_ADDRESS],
          has_provided_phone: !!this.values[QK.MOBILE_NUMBER]
        }
      })
    },
    async onNext() {
      let hasValidInterview = this.hasSavedInterview && !this.hasExpired
      if (!hasValidInterview) {
        const auth = getAuth()

        let account = this.getAccount()
        if (!auth.currentUser) {
          account = await this.setupAccount()
        }
        await this.createNewInterview(account.id)
        await this.checkAgent()
      }
      this.setMarketOnInterview()
      this.analyticsTracking()
      this.identify()

      const submitResult = await this.submitAnswers()
      if (!submitResult.success) {
        this.errors = submitResult.errors
        return { navigate: false }
      }

      this.isProcessing = false
      return { navigate: true }
    },

    identify() {
      const properties = {
        firstName: this.values[QK.FIRST_NAME],
        lastName: this.values[QK.LAST_NAME],
        phone: this.values[QK.MOBILE_NUMBER],
        email: this.values[QK.EMAIL_ADDRESS].toLowerCase(),
        source: 'app_contactInfoSaved'
      }
      if (this.imoAgent !== undefined) {
        properties.leadSource = LeadSource.IMO
      }
      this.$analytics.identify(properties)
    },
    inputId
  },

  mounted() {
    this.imoAgent = this.getCookie('imo:agentId') !== '' ? this.getCookie('imo:agentId') : undefined
    this.values = this.tempInterviewData || {}
    this.state = this.enteredProductVariant
    this.$analytics.track({
      event: 'app_startsInterview',
      url: this.$route.path
    })

    if (this.interview) {
      this.values = this.answersForKeys(this.questionKeys) || {}
      this.acceptedNotices = this.parseBool(this.values[QK.ACCEPTED_NOTICES])
      const productVariant = this.interview.product?.variant
      const mappedVariants = {
        TEXAS_STATE: 'TX',
        UTAH_STATE: 'UT'
      }
      const mappedState = mappedVariants[productVariant]
      if (mappedState) {
        this.state = mappedState
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

.initial-step {
  &__mobile-entry {
    .v-text-field__prefix {
      padding: 0 5px 0 0;
      color: rgba($c-primary, 0.7) !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import 'src/assets/styles/interview';
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/transitions';

$lg-initial-content-max-width: 740px;

.initial-step {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    @include lg {
      width: $lg-initial-content-max-width;
    }
  }
  &__name-entry {
    display: grid;
    grid-template-columns: 1fr 80px;
    column-gap: 20px;
    row-gap: 20px;
    @include lg {
      grid-template-columns: 260px 80px 3fr;
    }
  }
  &__last-name {
    grid-column: 1 / span 2;
    @include lg {
      grid-column: unset;
    }
  }
  &__contact-info {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 20px;
    @include md {
      grid-template-columns: 1fr 1fr;
    }
    @include lg {
      grid-template-columns: 260px 3fr;
    }
  }
  &__state {
    max-width: 140px;
    @include lg {
      max-width: unset;
    }
  }
  &__consent-box {
    margin-top: 30px;
  }
  &__state-section {
    display: grid;
    align-items: end;
    column-gap: 40px;
    margin-top: 30px;
    @include md {
      grid-template-columns: auto 1fr;
    }
    @include lg {
      grid-template-columns: 160px auto;
    }
  }
  &__state-notice {
    margin-bottom: 20px;
  }
  &__state-modal {
    max-width: 300px !important;
  }
}

.initial-notices {
  font-size: 1em;
  word-wrap: break-word;
  word-break: break-word;
  a {
    display: inline;
  }
}

.legal-container {
  padding: 25px 0;
}

::v-deep .v-input__slot {
  align-items: flex-start;
  label {
    padding-top: 3px;
  }
}

.sub-content-list {
  list-style: none;
  padding-left: 12px;
  margin-bottom: 3rem;
  margin-top: 2rem;

  @include md() {
    margin-top: 2rem;
  }

  li {
    margin-bottom: 8px;
  }
}

.legal-consent {
  margin-top: 2rem;
  font-size: 0.75rem;
}
</style>
