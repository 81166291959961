<template>
  <div class="imo-dashboard">
    <div class="main-navigation-header mq-container main-navigation-header___navigation-header">
      <NavigationHeader>
        <p class="imo-dashboard__nav-name" v-if="isAgent && !agentLoading && $store.state.imo">{{ $store.state.imo.IMOAgent.name }} </p>
        <p class="imo-dashboard__nav-name" v-else-if="!isAgent && !agencyLoading  && $store.state.imo">
          <span v-if="!hasImage">{{ $store.state.imo.IMOAgency.name }}</span>
          <img v-else :src="imagePath" />
        </p>
        <div class="imo-dashboard__nav"><router-link class="dashboard__navigation-link" to="/signout">Logout</router-link></div>
      </NavigationHeader>
    </div>
    <div class="imo-dashboard__wrapper mq-container">
      <h1 class="df-header-text">Dashboard</h1>
      <div v-if="isAgent" class="imo-dashboard__agent-content">
        <div class="imo-dashboard__client-cases">
          <h2>Open applications</h2>
          <v-data-table
            :disable-sort="true"
            :hide-default-footer="true"
            :headers="clientCasesHeaders"
            :items="$store.state.imo.IMOAgentOpen"
            :loading="agentLoading"
            :items-per-page="-1"
            width="100%"
            class="theme--primary"
            item-key="id + accountId"
          >
            <template v-slot:[`item.created`]="{ value }">{{ moment(value).format("MM/DD/YY") }}</template>
            <template v-slot:[`item.updated`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
            <template v-slot:[`item.id`]="{ item }"><a class="imo-dashboard__detail-button" @click="showModal('imo-modal', item)">Detail</a></template>
          </v-data-table>
        </div>
        <div class="imo-dashboard__policies">
          <h2>In-force policies</h2>
          <v-data-table
            :disable-sort="true"
            :hide-default-footer="true"
            :headers="clientPolicyHeaders"
            :items="$store.state.imo.IMOAgentClosed"
            :loading="agentLoading"
            :items-per-page="-1"
            width="100%"
            class="theme--primary"
            item-key="id + accountId"
          >
            <template v-slot:[`item.applicant.phoneNumbers`]="{ value }">{{ value.length > 0 ? value[0].number : '' }}</template>
            <template v-slot:[`item.id`]="{ item }">
              <div class="imo-dashboard__button-wrapper">
                <a class="imo-dashboard__detail-button" @click="showModal('imo-modal', item)">Details</a> |
                <a class="imo-dashboard__detail-button" @click="downloadPolicy(item)">View policy</a>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
      <div v-else class="imo-dashboard__agency-content">
        <div class="imo-dashboard__client-cases">
          <h2>Open applications</h2>
          <v-data-table
            :disable-sort="true"
            :hide-default-footer="true"
            :headers="agencyCasesHeaders"
            :items="$store.state.imo.IMOAgencyOpen"
            :loading="agencyLoading"
            :items-per-page="-1"
            width="100%"
            class="theme--primary"
            item-key="id + accountId"
          >
            <template v-slot:[`item.created`]="{ value }">{{ moment(value).format("MM/DD/YY") }}</template>
            <template v-slot:[`item.updated`]="{ value }">{{ moment(value).format("MM/DD/YY") }}</template>
            <template v-slot:[`item.agentCommission`]="{ value }">{{ value !== null ? formatCurrency(value) : '-' }}</template>
            <template v-slot:[`item.id`]="{ item }"><a class="imo-dashboard__detail-button" @click="showModal('imo-modal', item)">Details</a></template>
          </v-data-table>
        </div>
        <div class="imo-dashboard__policies">
          <h2>In-force policies</h2>
          <v-data-table
            :disable-sort="true"
            :hide-default-footer="true"
            :headers="agencyPolicyHeaders"
            :items="$store.state.imo.IMOAgencyClosed"
            :loading="agencyLoading"
            :items-per-page="-1"
            width="100%"
            class="theme--primary"
            item-key="id + accountId"
          >
            <template v-slot:[`item.applicant.phoneNumbers`]="{ value }">{{ value.length > 0 ? value[0].number : '' }}</template>
            <template v-slot:[`item.id`]="{ item }">
              <div class="imo-dashboard__button-wrapper">
                <a class="imo-dashboard__detail-button" @click="showModal('imo-modal', item)">Detail</a> |
                <a class="imo-dashboard__detail-button" @click="downloadPolicy(item)">View policy</a>
              </div>
            </template>
            <template v-slot:[`item.agentCommission`]="{ value }">{{ value !== null ? formatCurrency(value) : '-' }}</template>
          </v-data-table>
        </div>
      </div>
    </div>
    <IMOModal
      :modal-data="modalData"
      :show="isShowingModal('imo-modal')"
      @close="hideModal('imo-modal')"
    />
  </div>

</template>

<script>

import NavigationHeader from '@/components/navigation/NavigationHeader'
import IMOModal from '@/components/IMO/IMOModal'
import { formatCurrency } from '@/utils/number-formatter'

import { RouteName } from '@/router/routes/constants'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'IMODashboard',
  components: { IMOModal, NavigationHeader },
  data () {
    return {
      agentLoading: undefined,
      agencyLoading: undefined,
      agentOpen: undefined,
      agentId: undefined,
      isAgency: undefined,
      isAgent: undefined,
      clientCases: undefined,
      clientPolicies: undefined,
      clientCasesHeaders: [
        {
          text: 'Client',
          value: 'applicant.fullName'
        },
        {
          text: 'Last Updated',
          value: 'updated'
        },
        {
          text: 'Status',
          value: 'imoStatus'
        },
        {
          text: '',
          value: 'id'
        }
      ],
      clientPolicyHeaders: [
        {
          text: 'Client',
          value: 'applicant.fullName'
        },
        {
          text: 'Id',
          value: 'policy.policyId'
        },
        {
          text: 'Date Issued',
          value: 'policy.dateIssued'
        },
        {
          text: '',
          value: 'id'
        }
      ],
      agencyCasesHeaders: [
        {
          text: 'Client',
          value: 'applicant.fullName'
        },
        {
          text: 'Agent',
          value: 'agent.name'
        },
        {
          text: 'Last Updated',
          value: 'updated'
        },
        {
          text: 'Commission',
          value: 'agentCommission'
        },
        {
          text: 'Status',
          value: 'imoStatus'
        },
        {
          text: '',
          value: 'id'
        }
      ],
      agencyPolicyHeaders: [
        {
          text: 'Client',
          value: 'applicant.fullName'
        },
        {
          text: 'Agent',
          value: 'agent.name'
        },
        {
          text: 'Date Issued',
          value: 'policy.dateIssued'
        },
        {
          text: 'Commission',
          value: 'agentCommission'
        },
        {
          text: '',
          value: 'id'
        }

      ],
      hasImage: false,
      imagePath: undefined,
      modalData: undefined,
      visibleModalKey: undefined
    }
  },
  methods: {
    ...mapActions('policy', ['getPolicyDownloadURL']),
    ...mapActions('imo', ['getIMOAgentInterviews', 'getIMOAgencyInterviews']),
    ...mapGetters('account', ['currentAccount']),
    moment,
    formatCurrency,
    async downloadPolicy (item) {
      const url = await this.getPolicyDownloadURL(item.policy.policyId)
      if (url) {
        window.location.href = url
      }
    },
    showModal (key, val) {
      this.modalData = val
      this.visibleModalKey = key
    },
    isShowingModal (key) {
      return this.visibleModalKey === key
    },
    hideModal (key) {
      if (this.visibleModalKey === key) {
        this.visibleModalKey = undefined
        this.modalData = undefined
      }
    },
    async checkImage () {
      try {
        const url = `https://cdn.dayforward.com/images/agencies/${this.agencyId}.jpg`
        const res = await fetch(url, { method: 'HEAD' })
        if (res.ok) {
          // use the url
          this.hasImage = true
          return res.url
        }
      } catch (e) {
        this.hasImage = false
        return undefined
      }
    }
  },
  async mounted () {
    this.imagePath = await this.checkImage()
  },
  async beforeMount () {
    const account = this.currentAccount()
    if (account.userRole === 'OWNER') {
      this.isAgent = false
      this.agencyLoading = true
      this.agencyId = account.agencyId
      await this.getIMOAgencyInterviews({ agencyId: account.agencyId })
      this.agencyLoading = false
    } else if (account.isAgent) {
      this.isAgent = true
      this.agentLoading = true
      await this.getIMOAgentInterviews({ agentId: account.agentId })
      this.agentLoading = false
    } else {
      await this.$router.replace({ name: RouteName.HOME })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.imo-dashboard {

  h2 {
    margin-bottom: 2rem;
  }

  &__wrapper {
    margin-top: 2rem;
  }
  &__agency-content,
  &__agent-content {
    margin: 3rem 0;
  }

  &__policies {
    margin-top: 3rem;
  }
  &__button-wrapper {
    width: 100%;
    text-align: right;
  }
  &__detail-button {
    text-align: right;

  }

  ::v-deep .imo-dashboard__detail-button {
    text-align: right;
  }

  &__nav-name {
    margin-right: 20px;
    img {
      max-height: 64px;
    }
  }

  ::v-deep .theme--light.v-data-table {
    width: 100% !important;
    max-width: 100% !important;
    background-color: $c-eggshell;

    .v-select.table-input {
      font-size: 0.875rem !important;
      width: 120px;
    }
  }
}

.main-navigation__content {
  display: none;
  @include md {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    -moz-column-gap: 25px;
    column-gap: 25px;
  }
}
.main-navigation-header {
  height: 64px;
}
.menu-item {
  overflow: auto;
  font-size: 1.35rem;
  @include md {
    font-size: 1.6rem;
    margin-right: 0;
  }

  &__mobile {
    margin-top: 8px;
    margin-right: 12px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.main-navigation-header__signin {
  font-size: 16px;
  font-weight: 600;
  top: -3px;
  position: relative;
  @include md {
    font-size: 16px;
    margin-top: 0;
    top: 0;
  }
}

.menu-reveal-enter-active {
  transition: left 0.25s cubic-bezier(0.09, 0.11, 0.24, 0.91);
}
.menu-reveal-leave-active {
  transition: left 0.2s ease;
}

.menu-reveal-enter,
.menu-reveal-leave-to {
  left: 100%;
}

</style>
