import SignIn from '@/views/auth/SignIn'
import Dashboard from '@/views/admin/Dashboard'
import CustomerDetail from '@/views/admin/CustomerDetail'
import IMODasboard from '@/views/IMO/IMODasboard'
import { AdminPathPrefix, IMOPathPrefix } from '@/router/routes/constants'
export default [
  {
    path: AdminPathPrefix,
    name: 'admin-signin',
    component: SignIn,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: `${AdminPathPrefix}/dashboard`,
    name: 'admin-dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: `${AdminPathPrefix}/user/:accountId/:id`,
    name: 'admin-detail-account',
    component: CustomerDetail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: `${AdminPathPrefix}/admin/user/:id/`,
    name: 'admin-detail-no-account',
    component: CustomerDetail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: IMOPathPrefix,
    name: 'imo',
    component: SignIn,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },

  {
    path: `${IMOPathPrefix}/signin`,
    name: 'imo-signin',
    component: SignIn,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: `${IMOPathPrefix}/dashboard`,
    name: 'imo-dashboard',
    component: IMODasboard,
    meta: {
      requiresAuth: true,
      requiresAgent: true
    }
  }
]
