<template>
  <div class="imo-modal">
    <Modal
      title="Customer detail"
      :showActions="false"
      v-model="isShowing"
      @close="onClose"
      persistent
    >
      <template v-slot:content>
        <div class="imo-modal__detail">
          <p><span>Name:</span> {{ modalData.applicant.fullName }}</p>
          <p><span>Phone number:</span> {{ modalData.applicant.phoneNumbers.length > 0 ? modalData.applicant.phoneNumbers[0].number : '' }}</p>
          <p><span>Email:</span> {{ modalData.applicant.emailAddress ? modalData.applicant.emailAddress : "no data" }} </p>
          <p><span>Id:</span> {{ modalData.id }}</p>
          <p><span>Created:</span> {{ moment(modalData.created).format("L LTS -Z") }}</p>
          <p><span>Updated:</span> {{ moment(modalData.updated).format("L LTS -Z") }}</p>
          <p><span>Status:</span> {{ modalData.imoStatus }}</p>
          <p v-if="modalData.monthlyPremium"><span>Premium:</span> ${{ modalData.monthlyPremium }}</p>
<!--          <p><span>Commission:</span> {{ formatCurrency(modalData.agentCommission) }}</p>-->
          <p v-if="modalData.policy"><span>Date issued:</span> {{ modalData.policy.dateIssued }}</p>
          <p v-if="modalData.agent"><span>Agent:</span> {{ modalData.agent.agentEmail }}</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import moment from 'moment'
import { formatCurrency } from '@/utils/number-formatter'

export default {
  name: 'IMOModal.js',
  components: { Modal },
  data () {
    return {
      isShowing: this.show
    }
  },
  props: {
    show: Boolean,
    modalData: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: undefined,
          created: undefined,
          updated: undefined,
          status: undefined,
          applicant: {
            fullName: undefined,
            phoneNumbers: [],
            emailAddress: undefined
          }
        }
      }
    }
  },

  watch: {
    show (newVal) {
      this.isShowing = newVal
    }
  },

  methods: {
    moment,
    formatCurrency,
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.imo-modal {
  &__detail {
   p {
     font-size: 14px;
   }
    span {
      font-weight: bold;
    }
   }
}

</style>
