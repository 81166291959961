import gql from 'graphql-tag'

import { Snapshot } from './snapshot'

import {
  InterviewStatus,
  QuestionKey,
  QuestionCategory,
  EmploymentCode,
  InterviewFlagType,
  InterviewFlagCause,
  InterviewFlagSource,
  BenefitTermCode,
  BeneficiaryRelationship,
  BeneficiaryRelationshipReadable,
  BeneficiaryRelationshipIndividual,
  InitialInterviewSections,
  FinalizePriceInterviewSections,
  BenefitType,
  SectionID,
  SectionDisplayOption,
  DiagnosisCategories
} from './constants'

export {
  InterviewStatus,
  QuestionKey,
  QuestionCategory,
  EmploymentCode,
  InterviewFlagType,
  InterviewFlagCause,
  InterviewFlagSource,
  BenefitTermCode,
  BeneficiaryRelationship,
  BeneficiaryRelationshipReadable,
  BeneficiaryRelationshipIndividual,
  InitialInterviewSections,
  FinalizePriceInterviewSections,
  BenefitType,
  SectionID,
  SectionDisplayOption,
  DiagnosisCategories,
  Snapshot
}

export default {
  getEmailRedirect: (email) => ({
    query: gql`
      query getEmailRedirect($email: String!) {
        getEmailRedirect(email: $email) {
          redirectStatus
        }
      }
    `,
    variables: {
      email
    }
  }),
  find: (id) => ({
    query: gql`
      query FindInterview($id: String!) {
        interview(id: $id) {
          id
          accountId
          policyId
          status
          metadata {
            key
            value
          }
          flags {
            type
            cause
            source
            name
            description
          }
          product {
            type
            variant
            version
            maxAnnualBenefit
            minAnnualBenefit
          }
          currentSection {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
          questions {
            key
            text
            hint
            label
            placeholder
            defaultCategory
            allowsNone
            active
            options {
              text
              value
            }
            valueType
            multipleChoice
            required
          }
          reflexives {
            targetKey
            sourceKey
            match
            isSatisfied
            conditions {
              operator
              value
              isSatisfied
            }
          }
          topLevelQuestionKeys
          quote {
            isEstimate
            annualIncome
            termReason {
              code
              description
            }
            dayOneCoverage
            termRecommended
            youngestChildAge
            hasChild
          }
        }
      }
    `,
    variables: {
      id
    }
  }),

  create: (state, bind) => ({
    mutation: gql`
      mutation createInterview($state: State!, $bind: Boolean) {
        createInterview(state: $state, bind: $bind) {
          id
          accountId
          status
          flags {
            type
            cause
            source
            name
            description
          }
          product {
            type
            variant
            version
            maxAnnualBenefit
            minAnnualBenefit
          }
          questions {
            key
            defaultCategory
            answers
            defaultAnswers
            answered
            multipleChoice
            required
            valueType
            allowsNone
            active
            text
            hint
            label
            placeholder
            options {
              text
              value
            }
          }
          reflexives {
            targetKey
            sourceKey
            match
            isSatisfied
            conditions {
              operator
              value
              isSatisfied
            }
          }
          currentSection {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
        }
      }
    `,
    variables: {
      state,
      bind
    }
  }),

  statusUpdate: (id) => ({
    query: gql`
      subscription onStatusUpdate($id: String!) {
        interviewStatus(id: $id) {
          status
          flags {
            type
            cause
            source
            name
            description
          }
        }
      }
    `,
    variables: {
      id
    }
  }),

  getStatus: (id) => ({
    query: gql`
      query GetInterviewStatus($id: String!) {
        interview: interviewSummary(id: $id) {
          status
          flags {
            type
            cause
            source
            name
            description
          }
          currentSectionId
          quoteLocked
          policyId
          accountId
          created
          updated
          agentId
          productVariant
        }
      }
    `,
    variables: {
      id
    }
  }),

  submit: (id) => ({
    mutation: gql`
      mutation SubmitInterview($id: String!) {
        submitInterview(interviewID: $id) {
          interviewID
          success
          submitStatus
          error
        }
      }
    `,
    variables: {
      id
    }
  }),

  submitAsync: (id) => ({
    mutation: gql`
      mutation SubmitInterview($id: String!) {
        submitInterview(interviewID: $id, async: true) {
          interviewID
          success
          submitStatus
          error
        }
      }
    `,
    variables: {
      id
    }
  }),

  updateAnswers: (id, answers) => ({
    mutation: gql`
      mutation UpdateAnswer($id: String!, $answers: [AnswerInput!]!) {
        putInterviewAnswers(id: $id, answers: $answers) {
          questionKey
          success
          message
          values
          answer {
            familyHistory {
              diagnoses
              severity
              relationship
            }
          }
          reflexives {
            sourceKey
            targetKey
            isSatisfied
            category
            target {
              key
              active
              required
              answered
              answers
            }
          }
        }
      }
    `,
    variables: {
      id,
      answers
    }
  }),

  validateAnswers: (id, answers) => ({
    query: gql`
      query ValidateAnswers($id: String!, $answers: [AnswerInput!]!) {
        validateInterviewAnswers(id: $id, answers: $answers) {
          questionKey
          success
          message
          values
        }
      }
    `,
    variables: {
      id,
      answers
    }
  }),

  questions: (id, where) => ({
    query: gql`
      query Questions($id: String!, $where: QuestionWhere!) {
        questions(interviewID: $id, where: $where) {
          key
          defaultCategory
          text
          hint
          label
          placeholder
          allowsNone
          options {
            text
            value
          }
          answers
          answer {
            familyHistory {
              diagnoses
              severity
              relationship
            }
          }
          defaultAnswers
          answered
          valueType
          multipleChoice
          required
          active
          concerns
        }
      }
    `,
    variables: {
      id,
      where
    }
  }),

  reflexives: (id, where) => ({
    query: gql`
      query Reflexives($id: String!, $where: ReflexiveWhere!) {
        reflexives(interviewID: $id, where: $where) {
          targetKey
          sourceKey
          match
          category
          conditions {
            operator
            value
            isSatisfied
          }
          isSatisfied
        }
      }
    `,
    variables: {
      id,
      where
    }
  }),

  findOccupations: (term, skip = 0, take = 25) => ({
    query: gql`
      query Occupations($term: String!, $skip: Int = 0, $take: Int = 5) {
        occupations(where: { match: $term }, skip: $skip, take: $take) {
          key
          name
        }
      }
    `,
    variables: {
      term,
      skip,
      take
    }
  }),

  occupationsWithKeys: (keys) => ({
    query: gql`
      query OccupationsWithKeys($keys: [String!]!) {
        occupations(where: { keys: $keys }) {
          key
          name
        }
      }
    `,
    variables: {
      keys
    }
  }),

  findMedications: (term, skip = 0, take = 5) => ({
    query: gql`
      query MedicationsSearch($term: String!, $take: Int = 5, $skip: Int = 0) {
        medications(where: { match: $term }, skip: $skip, take: $take) {
          key
          activeName
        }
      }
    `,
    variables: {
      term,
      skip,
      take
    }
  }),

  medicationsWithKeys: (keys) => ({
    query: gql`
      query MedicationsWithKeys($keys: [String!]!) {
        medications(where: { keys: $keys }) {
          key
          activeName
        }
      }
    `,
    variables: {
      keys: keys
    }
  }),

  medicationsWithNames: (names) => ({
    query: gql`
      query MedicationsWithNames($names: [String!]!) {
        medications(where: { names: $names }) {
          key
          activeName
        }
      }
    `,
    variables: {
      names: names
    }
  }),

  findDiagnoses: (term, skip = 0, take = 5) => ({
    query: gql`
      query DiagnosesSearch($term: String!, $take: Int = 5, $skip: Int = 0) {
        diagnoses(where: { match: $term }, skip: $skip, take: $take) {
          code
          description
        }
      }
    `,
    variables: {
      term,
      skip,
      take
    }
  }),

  diagnosesWithCodes: (codes) => ({
    query: gql`
      query DiagnosesWithCodes($codes: [String!]!) {
        diagnoses(where: { codes: $codes }) {
          code
          abbreviatedDescription
          description
          category {
            code
            title
          }
        }
      }
    `,
    variables: {
      codes: codes
    }
  }),
  getQuickQuote: (age, gender, income, childAge) => ({
    query: gql`
      query QuoteEstimate($age: Int!, $gender: Gender!, $income: Int!, $childAge: Int) {
        class1: quoteEstimate(age: $age, annualIncome: $income, gender: $gender, childAge: $childAge, class: ONE) {
          success
          reason
          quote {
            annualBenefit
            benefitAmount
            termReason {
              code
              untilChildAge
              untilInsuredAge
            }
            dayOneCoverage
            premium
            termEndDate
            termMonths: termRecommended
          }
        }
        class2: quoteEstimate(age: $age, annualIncome: $income, gender: $gender, childAge: $childAge, class: TWO) {
          success
          reason
          quote {
            annualBenefit
            benefitAmount
            dayOneCoverage
            termReason {
              code
              untilChildAge
              untilInsuredAge
            }
            premium
            termEndDate
            termMonths: termRecommended
          }
        }
        class3: quoteEstimate(age: $age, annualIncome: $income, gender: $gender, childAge: $childAge, class: THREE) {
          success
          reason
          quote {
            annualBenefit
            benefitAmount
            dayOneCoverage
            termReason {
              code
              untilChildAge
              untilInsuredAge
            }
            premium
            termEndDate
            termMonths: termRecommended
          }
        }
        class4: quoteEstimate(age: $age, annualIncome: $income, gender: $gender, childAge: $childAge, class: FOUR) {
          success
          reason
          quote {
            annualBenefit
            benefitAmount
            dayOneCoverage
            termReason {
              code
              untilChildAge
              untilInsuredAge
            }
            premium
            termEndDate
            termMonths: termRecommended
          }
        }
      }
    `,
    variables: {
      age,
      gender,
      income,
      childAge
    }
  }),
  /**
   * @param {string} id interview ID
   * @param {Object} parameter
   * @param {number} parameter.benefitAmount
   * @param {number} parameter.untilChildAge
   * @param {number} parameter.termYears
   */
  getQuote: (id, parameter) => ({
    query: gql`
      query GetInterviewQuote($id: String!, $parameter: QuoteOverride) {
        quote(interviewID: $id, override: $parameter) {
          success
          quoteLow {
            benefitAmount
            benefitType
            benefitLimit {
              minimum
              maximum
            }
            benefitAmountOptions
            dayOneCoverage
            hasPremium
            premium
            premiumPer1kIncome
            premiumPer1YearExtension
            rate
            employment {
              code
              description
            }
            annualBenefit
            annualIncome
            termRecommended
            hasOverride
            locked
            termMonths: termRecommended
            termEndDate
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termLimit {
              minimum
              maximum
            }
            youngestChildAgeLimit {
              minimum
              maximum
            }
            hasChild
            youngestChildAge
            insuredAge
            insuredAgeLimit {
              minimum
              maximum
            }
            isEstimate
            childExtensionAvailable
            childExtensionMaxAge
            childExtensionMaxYearsRemaining
            subscriptionFee
            incomeRiderMaxAnnualIncome
            incomeRiderMaxTotalBenefit
          }
          quoteMedium {
            benefitAmount
            benefitType
            benefitLimit {
              minimum
              maximum
            }
            benefitAmountOptions
            hasPremium
            premium
            premiumPer1kIncome
            premiumPer1YearExtension
            rate
            employment {
              code
              description
            }
            annualBenefit
            annualIncome
            termRecommended
            hasOverride
            locked
            termMonths: termRecommended
            termEndDate
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termLimit {
              minimum
              maximum
            }
            youngestChildAgeLimit {
              minimum
              maximum
            }
            hasChild
            youngestChildAge
            insuredAge
            insuredAgeLimit {
              minimum
              maximum
            }
            isEstimate
            childExtensionAvailable
            childExtensionMaxAge
            childExtensionMaxYearsRemaining
            subscriptionFee
            incomeRiderMaxAnnualIncome
            incomeRiderMaxTotalBenefit
          }
          quote {
            benefitAmount
            benefitType
            benefitLimit {
              minimum
              maximum
            }
            benefitAmountOptions
            dayOneCoverage
            hasPremium
            premium
            premiumPer1kIncome
            premiumPer1YearExtension
            rate
            employment {
              code
              description
            }
            annualBenefit
            annualIncome
            termRecommended
            hasOverride
            locked
            termMonths: termRecommended
            termEndDate
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termLimit {
              minimum
              maximum
            }
            youngestChildAgeLimit {
              minimum
              maximum
            }
            hasChild
            youngestChildAge
            insuredAge
            insuredAgeLimit {
              minimum
              maximum
            }
            isEstimate
            childExtensionAvailable
            childExtensionMaxAge
            childExtensionMaxYearsRemaining
            subscriptionFee
            incomeRiderMaxAnnualIncome
            incomeRiderMaxTotalBenefit
          }
        }
      }
    `,
    variables: {
      id,
      parameter
    }
  }),

  /**
   * @param {string} id interview ID
   * @param {Object} parameter
   * @param {number} parameter.benefitAmount
   * @param {number} parameter.untilChildAge
   * @param {number} parameter.termYears
   */
  updateQuote: (id, parameter) => ({
    mutation: gql`
      mutation UpdateQuote($id: String!, $parameter: QuoteOverride!) {
        updateQuote: quoteUpdate(interviewID: $id, override: $parameter) {
          benefitAmount
          benefitAmountOptions
          benefitType
          benefitLimit {
            minimum
            maximum
          }
          hasPremium
          premium
          premiumPer1kIncome
          premiumPer1YearExtension
          rate
          employment {
            code
            description
          }
          annualIncome
          termRecommended
          hasOverride
          termEndDate
          termReason {
            code
            description
            untilChildAge
            untilInsuredAge
          }
          termLimit {
            minimum
            maximum
          }
          youngestChildAgeLimit {
            minimum
            maximum
          }
          hasChild
          youngestChildAge
          insuredAge
          insuredAgeLimit {
            minimum
            maximum
          }
          isEstimate
          childExtensionAvailable
          childExtensionMaxAge
          childExtensionMaxYearsRemaining
          subscriptionFee
          incomeRiderMaxAnnualIncome
          incomeRiderMaxTotalBenefit
          locked
        }
      }
    `,
    variables: {
      id,
      parameter
    }
  }),

  /**
   * @param {string} id interview ID
   */
  resetQuote: (id) => ({
    mutation: gql`
      mutation ResetInterviewQuote($id: String!) {
        resetQuote: quoteReset(interviewID: $id) {
          benefitAmount
          benefitAmountOptions
          benefitType
          benefitLimit {
            minimum
            maximum
          }
          hasPremium
          premium
          premiumPer1kIncome
          premiumPer1YearExtension
          rate
          employment {
            code
            description
          }
          annualIncome
          annualBenefit
          termRecommended
          hasOverride
          termEndDate
          termReason {
            code
            description
            untilChildAge
            untilInsuredAge
          }
          termLimit {
            minimum
            maximum
          }
          youngestChildAgeLimit {
            minimum
            maximum
          }
          hasChild
          youngestChildAge
          insuredAge
          insuredAgeLimit {
            minimum
            maximum
          }
          isEstimate
          childExtensionAvailable
          childExtensionMaxAge
          subscriptionFee
          incomeRiderMaxAnnualIncome
          incomeRiderMaxTotalBenefit
          locked
        }
      }
    `,
    variables: {
      id
    }
  }),

  adminReplaceAccountInterview: (id, accountId) => ({
    mutation: gql`
      mutation AdminReplaceAccountInterview($id: String!, $accountId: String!) {
        adminReplaceAccountInterview(id: $id, accountId: $accountId)
      }
    `,
    variables: {
      id,
      accountId
    }
  }),

  bind: (id, authReason, accountId) => ({
    mutation: gql`
      mutation BindInterview($id: String!, $authReason: AuthReason, $accountId: String) {
        bindInterview(id: $id, authReason: $authReason, accountId: $accountId)
      }
    `,
    variables: {
      id,
      authReason,
      accountId
    }
  }),
  signatureEmbed: (id) => ({
    mutation: gql`
      mutation SignatureEmbed($id: String!) {
        interviewSignatureEmbed(id: $id) {
          url
        }
      }
    `,
    variables: {
      id
    }
  }),

  getIsSupportedState: (address) => ({
    query: gql`
      query IsSupportedState($address: String!) {
        isSupportedState(address: $address) {
          isSupported
          message
        }
      }
    `,
    variables: {
      address
    }
  }),

  getInterviews: (sort, order, skip, take, statuses, hasAccount, query) => ({
    query: gql`
      query GetInterviews(
        $query: String
        $sort: InterviewSortField = CREATED
        $statuses: [InterviewStatus!] = [
          ACCEPTED
          ERROR
          EXPIRED
          INCOMPLETE
          INELIGIBLE
          NEEDS_REVIEW
          READY_TO_SIGN
          READY_TO_SUBMIT
          REJECTED
          SUBMITTED
          AWAITING_SIGNATURE_CONFIRMATION
          AWAITING_SIGNATURE_COMPLETION
          READY_FOR_CHECKOUT
        ]
        $hasAccount: Boolean
        $order: SortOrder = DESC
        $skip: Int
        $take: Int = 25
      ) {
        interviewSummaries(
          where: { statuses: $statuses, hasAccount: $hasAccount, query: $query }
          take: $take
          sort: $sort
          order: $order
          skip: $skip
        ) {
          interviewID
          policyId
          status
          quoteMonthlyPremium
          applicant {
            accountID
            firstName
            lastName
            emailAddress
            age
            annualIncome
            gender
            bmi
            address {
              line1
              line2
              city
              state
              postalCode
            }
            phoneNumbers {
              number
            }
          }
          currentSectionId
          flags {
            type
            cause
            source
            name
            description
          }
          riskScore {
            class
            flags
            info
          }
          created
          updated
          accountId
          agentId
          product {
            vendor
          }
          productVariant
        }
      }
    `,
    variables: {
      sort,
      order,
      skip,
      take,
      statuses,
      query,
      hasAccount
    }
  }),
  getNextInterviews: (sort, order, skip, take, hasAccount, query) => ({
    query: gql`
      query GetInterviews(
        $query: String
        $sort: InterviewSortField = CREATED
        $statuses: [InterviewStatus!] = [
          ACCEPTED
          ERROR
          EXPIRED
          INCOMPLETE
          INELIGIBLE
          NEEDS_REVIEW
          READY_TO_SIGN
          READY_TO_SUBMIT
          REJECTED
          SUBMITTED
          AWAITING_SIGNATURE_CONFIRMATION
          AWAITING_SIGNATURE_COMPLETION
          READY_FOR_CHECKOUT
        ]
        $hasAccount: Boolean
        $order: SortOrder = DESC
        $skip: Int
        $take: Int = 25
      ) {
        interviewSummaries(
          where: { statuses: $statuses, hasAccount: $hasAccount, query: $query }
          take: $take
          sort: $sort
          order: $order
          skip: $skip
        ) {
          accountId
        }
      }
    `,
    variables: {
      sort,
      order,
      skip,
      take,
      hasAccount,
      query
    }
  }),
  getInterviewStepCount: (id, group) => ({
    query: gql`
      query getInterviewStepCount($id: String!, $group: QuestionGroup) {
        interview(id: $id) {
          sectionMultiQuery: sections(where: { active: true, group: $group }) {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
        }
      }
    `,
    variables: {
      id,
      group
    }
  }),
  FetchInterviewQuoteAndSections: (id, where) => ({
    query: gql`
      query FetchInterviewQuoteAndSections($id: String!, $where: SectionWhere) {
        interview(id: $id) {
          sections(where: $where) {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
          quote {
            youngestChildAgeLimit {
              minimum
              maximum
            }
            benefitLimit {
              minimum
              maximum
            }
            termLimit {
              minimum
              maximum
            }
            benefitAmount
            benefitAmountOptions
            dayOneCoverage
            premium
            hasPremium
            termEndDate
            isEstimate
            annualIncome
            locked
            hasOverride
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termRecommended
            hasChild
            childExtensionMaxYearsRemaining
            youngestChildAge
          }
        }
      }
    `,
    variables: {
      id,
      where
    }
  }),
  FetchInterviewQuote: (id) => ({
    query: gql`
      query FetchInterviewQuote($id: String!) {
        interview(id: $id) {
          quote {
            youngestChildAgeLimit {
              minimum
              maximum
            }
            benefitLimit {
              minimum
              maximum
            }
            dayOneCoverage
            termLimit {
              minimum
              maximum
            }
            benefitAmount
            benefitAmountOptions
            premium
            hasPremium
            termEndDate
            isEstimate
            annualIncome
            locked
            hasOverride
            termReason {
              code
              description
              untilChildAge
              untilInsuredAge
            }
            termRecommended
            hasChild
            youngestChildAge
          }
        }
      }
    `,
    variables: {
      id
    }
  }),

  fetchInterviewCurrentSection: (id) => ({
    query: gql`
      query FetchInterviewCurrentSection($id: String!) {
        interview(id: $id) {
          currentSection {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
        }
      }
    `,
    variables: {
      id
    }
  }),

  fetchInterviewSection: (id, sectionId) => ({
    query: gql`
      query FetchInterviewSection($id: String!, $sectionId: SectionID) {
        interview(id: $id) {
          section(id: $sectionId) {
            id
            active
            activeHeader
            category
            concerns
            group
            incomplete
            title
            displayOptions
            hasQuestions
          }
        }
      }
    `,
    variables: {
      id,
      sectionId
    }
  }),

  requireFinalQuote: (id, requireFinalQuote) => ({
    mutation: gql`
      mutation InterviewRequireFinalQuote($id: String!, $requireFinalQuote: Boolean) {
        interview(id: $id, requireFinalQuote: $requireFinalQuote) {
          interview {
            id
            status
            flags {
              type
              cause
              source
              name
              description
            }
            quote {
              isEstimate
            }
          }
        }
      }
    `,
    variables: {
      id,
      requireFinalQuote
    }
  }),
  SetQuoteLocked: (interviewId, locked) => ({
    mutation: gql`
      mutation SetQuoteLocked($interviewId: String!, $locked: Boolean!) {
        interview(id: $interviewId, quoteLocked: $locked) {
          error {
            type
            message
          }
        }
      }
    `,
    variables: {
      interviewId,
      locked
    }
  }),
  RecordInteraction: (interviewId, interactionType) => ({
    mutation: gql`
      mutation RecordInteraction($interviewId: String!, $interactionType: InteractionType!) {
        interview(id: $interviewId, recordInteraction: $interactionType) {
          interview {
            id
            interactions {
              type
              time
            }
          }
          error {
            type
            message
          }
        }
      }
    `,
    variables: {
      interviewId,
      interactionType
    }
  }),
  getVerificationStatus: (interviewId) => ({
    query: gql`
      query GetVerificationStatus($interviewId: String!) {
        interview(id: $interviewId) {
          verificationStatus
        }
      }
    `,
    variables: {
      interviewId
    }
  }),

  setInterviewMetadata: (id, metadataInput) => ({
    mutation: gql`
      mutation SetInterviewMetadata($id: String!, $metadataInput: [MetadataInput!]) {
        interview(id: $id, metadata: $metadataInput) {
          error {
            type
            message
          }
        }
      }
    `,
    variables: {
      id,
      metadataInput
    }
  }),

  bindAgentToInterview: (id, agentId) => ({
    mutation: gql`
      mutation BindAgentToInterview($id: String!, $agentId: String) {
        interview(id: $id, agentId: $agentId) {
          error {
            type
            message
          }
        }
      }
    `,
    variables: {
      id,
      agentId
    }
  })
}
