import IMOAPI from '@/api/IMO'

const imoModule = {
  namespaced: true,
  state: {
    IMOAgent: undefined,
    IMOAgency: undefined,
    IMOAgentOpen: undefined,
    IMOAgentClosed: undefined,
    IMOAgencyOpen: undefined,
    IMOAgencyClosed: undefined
  },
  getters: {},
  actions: {
    async getAgent ({ rootState, commit }, payload) {
      const res = await rootState.apolloClient.query({
        ...IMOAPI.GetAgent(payload.agentId),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data.agent.id
      }
    },
    async getIMOAgentInterviews ({ rootState, commit }, payload) {
      const res = await rootState.apolloClient.query({
        ...IMOAPI.GetAgentInterviews(payload.agentId),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        commit('SET_IMO_AGENT_INTERVIEWS', res)
      }
    },
    async getIMOAgencyInterviews ({ rootState, commit }, payload) {
      const res = await rootState.apolloClient.query({
        ...IMOAPI.GetAgencyInterviews(payload.agencyId),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        commit('SET_IMO_AGENCY_INTERVIEWS', res)
      }
    }

  },
  mutations: {
    SET_IMO_AGENT_INTERVIEWS (state, interviews) {
      state.IMOAgent = interviews.data.agent
      state.IMOAgentOpen = interviews.data.open
      state.IMOAgentClosed = interviews.data.closed
    },
    SET_IMO_AGENCY_INTERVIEWS (state, interviews) {
      state.IMOAgency = interviews.data.agency
      state.IMOAgencyOpen = interviews.data.open
      state.IMOAgencyClosed = interviews.data.closed
    }
  }
}
export default imoModule
