var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"imo-dashboard"},[_c('div',{staticClass:"main-navigation-header mq-container main-navigation-header___navigation-header"},[_c('NavigationHeader',[(_vm.isAgent && !_vm.agentLoading && _vm.$store.state.imo)?_c('p',{staticClass:"imo-dashboard__nav-name"},[_vm._v(_vm._s(_vm.$store.state.imo.IMOAgent.name)+" ")]):(!_vm.isAgent && !_vm.agencyLoading  && _vm.$store.state.imo)?_c('p',{staticClass:"imo-dashboard__nav-name"},[(!_vm.hasImage)?_c('span',[_vm._v(_vm._s(_vm.$store.state.imo.IMOAgency.name))]):_c('img',{attrs:{"src":_vm.imagePath}})]):_vm._e(),_c('div',{staticClass:"imo-dashboard__nav"},[_c('router-link',{staticClass:"dashboard__navigation-link",attrs:{"to":"/signout"}},[_vm._v("Logout")])],1)])],1),_c('div',{staticClass:"imo-dashboard__wrapper mq-container"},[_c('h1',{staticClass:"df-header-text"},[_vm._v("Dashboard")]),(_vm.isAgent)?_c('div',{staticClass:"imo-dashboard__agent-content"},[_c('div',{staticClass:"imo-dashboard__client-cases"},[_c('h2',[_vm._v("Open applications")]),_c('v-data-table',{staticClass:"theme--primary",attrs:{"disable-sort":true,"hide-default-footer":true,"headers":_vm.clientCasesHeaders,"items":_vm.$store.state.imo.IMOAgentOpen,"loading":_vm.agentLoading,"items-per-page":-1,"width":"100%","item-key":"id + accountId"},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format("MM/DD/YY")))]}},{key:"item.updated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format("L LTS -Z")))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.showModal('imo-modal', item)}}},[_vm._v("Detail")])]}}],null,true)})],1),_c('div',{staticClass:"imo-dashboard__policies"},[_c('h2',[_vm._v("In-force policies")]),_c('v-data-table',{staticClass:"theme--primary",attrs:{"disable-sort":true,"hide-default-footer":true,"headers":_vm.clientPolicyHeaders,"items":_vm.$store.state.imo.IMOAgentClosed,"loading":_vm.agentLoading,"items-per-page":-1,"width":"100%","item-key":"id + accountId"},scopedSlots:_vm._u([{key:"item.applicant.phoneNumbers",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.length > 0 ? value[0].number : ''))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"imo-dashboard__button-wrapper"},[_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.showModal('imo-modal', item)}}},[_vm._v("Details")]),_vm._v(" | "),_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.downloadPolicy(item)}}},[_vm._v("View policy")])])]}}],null,true)})],1)]):_c('div',{staticClass:"imo-dashboard__agency-content"},[_c('div',{staticClass:"imo-dashboard__client-cases"},[_c('h2',[_vm._v("Open applications")]),_c('v-data-table',{staticClass:"theme--primary",attrs:{"disable-sort":true,"hide-default-footer":true,"headers":_vm.agencyCasesHeaders,"items":_vm.$store.state.imo.IMOAgencyOpen,"loading":_vm.agencyLoading,"items-per-page":-1,"width":"100%","item-key":"id + accountId"},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format("MM/DD/YY")))]}},{key:"item.updated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format("MM/DD/YY")))]}},{key:"item.agentCommission",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value !== null ? _vm.formatCurrency(value) : '-'))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.showModal('imo-modal', item)}}},[_vm._v("Details")])]}}],null,true)})],1),_c('div',{staticClass:"imo-dashboard__policies"},[_c('h2',[_vm._v("In-force policies")]),_c('v-data-table',{staticClass:"theme--primary",attrs:{"disable-sort":true,"hide-default-footer":true,"headers":_vm.agencyPolicyHeaders,"items":_vm.$store.state.imo.IMOAgencyClosed,"loading":_vm.agencyLoading,"items-per-page":-1,"width":"100%","item-key":"id + accountId"},scopedSlots:_vm._u([{key:"item.applicant.phoneNumbers",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.length > 0 ? value[0].number : ''))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"imo-dashboard__button-wrapper"},[_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.showModal('imo-modal', item)}}},[_vm._v("Detail")]),_vm._v(" | "),_c('a',{staticClass:"imo-dashboard__detail-button",on:{"click":function($event){return _vm.downloadPolicy(item)}}},[_vm._v("View policy")])])]}},{key:"item.agentCommission",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value !== null ? _vm.formatCurrency(value) : '-'))]}}],null,true)})],1)])]),_c('IMOModal',{attrs:{"modal-data":_vm.modalData,"show":_vm.isShowingModal('imo-modal')},on:{"close":function($event){return _vm.hideModal('imo-modal')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }